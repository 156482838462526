import React, {useEffect, useState} from 'react'
import {fetchProblemBySerial, fetchProblems} from "../../action/content";
import Problem from "./Problem";
import '../../css/problem-container.css'
import uuid from "react-uuid";

const ProblemContainer=props=>{

    const lang=props.match.params.lang;
    const level=props.match.params.level;
    const seriesId=props.match.params.series_id;
    const serial=props.match.params.serial;



    const [serialState,setSerialState]=useState(serial)
    const [problems,setProblems]=useState(null)

    const [tmpProblems,setTmpProblems]=useState(null)

    const updateSubmitStatus=(id,status)=>{
        setTmpProblems(problems.map(p=>{
            var obj=JSON.parse(JSON.stringify(p))
            if(obj.problem_id===id)
                obj.status=status
            return obj
        }))
    }


    const [problem,setProblem]=useState(null)

    // useEffect(()=>{
    //     fetchProblems(seriesId).then(res=>{
    //         setProblems(res)
    //         console.log(res)
    //     })
    // },[])

    const getProblem=()=>{
        fetchProblemBySerial(seriesId,serial).then(res=>{
            setProblem(res)
        })
    }



    const nextProblem=()=>{
        if(problem.serial<problems.length){
            setSerialState(problem.serial+1);
            props.history.push(`/lang/${lang}/level/${level}/series/${seriesId}/problem/${(problem.serial+1)}`)
        }else{
            props.history.push(`/lang/${lang}/level/${level}`)
        }

        if(tmpProblems!==null)setProblems(tmpProblems)
    }

    useEffect(()=>{
        //getProblem()
        //setSerialState(serial)


            fetchProblems(seriesId).then(res => {
                setProblems(res)
                //console.log(res)
            })






    },[seriesId,serialState])

    useEffect(()=>{
        if(problems!==null)
            setProblem(problems[serialState-1])
    },[problems])

    useEffect(()=>{
        //getProblem()
        //setSerialState(serial)


        // if(prevSerial!==null && prevSerial!==serial){
        //     console.log('Different')
        // }




    },[seriesId,serial])



    return(
        <div style={{padding:'20px'}}>
            {
                problems!==null?(
                    <div>
                        <hr className={'stepper-line'}/>
                        <div className={'stepper-container'}>
                            {
                                problems.map((p,i)=>{
                                    //console.log(serialState,i)
                                    if(serialState==i+1){
                                        return(
                                            <div className={`step-current`}>
                                                {(i+1)}
                                            </div>
                                        )
                                    }
                                    else{
                                        return(
                                            <div className={`step-pending ${p.status===0?'step-error':(p.status===1?'step-success':'')}`} onClick={()=>{setSerialState(i+1);props.history.push(`/lang/${lang}/level/${level}/series/${seriesId}/problem/${(i+1)}`);if(tmpProblems!==null)setProblems(tmpProblems)}}>
                                                {(i+1)}
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                ):(
                    <div/>
                )
            }
            {
                problem!==null?(
                    <Problem update={updateSubmitStatus} nProblem={problems.length} next={nextProblem} key={uuid()} level={level} history={props.history} problem={problem} serial={serial}/>
                ):(
                    <div/>
                )
            }
        </div>
    )
}

export default ProblemContainer
