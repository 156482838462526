export const setEnLang=(dispatcher)=>{
    dispatcher(enDispatch())
}

export const setBnLang=(dispatcher)=>{
    dispatcher(bnDispatch())
}

const bnDispatch=()=>{
    return {
        type:'LANG_BN'
    }
}
const enDispatch=()=>{
    return {
        type:'LANG_EN'
    }
}