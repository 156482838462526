import axios from "axios";
import Cookies from "universal-cookie";
import { base_url } from "../";
import { setLoading, showToast } from "../App";
const cookies = new Cookies();

export const fetchTopics = (lang, level) => {
  setLoading(true);
  return axios
    .get(base_url + "api/level/topics?level_id=" + (parseInt(level) + 5), {
      headers: { authorization: cookies.get("token") },
    })
    .then((res) => {
      setLoading(false);
      return res.data;
    })
    .catch((err) => {
      setLoading(false);
      showToast("Error fetching data");
    });
};

export const fetchSerieses = (topicID) => {
  setLoading(true);
  return axios
    .get(base_url + "topic/" + topicID, {
      headers: { authorization: cookies.get("token") },
    })
    .then((res) => {
      setLoading(false);
      return res.data;
    })
    .catch((err) => {
      setLoading(false);
      showToast("Error fetching data");
    });
};

export const fetchTests = async (lang, level) => {
  setLoading(true);
  return axios
    .get(base_url + "tests?level_id=" + (parseInt(level) + 5), {
      headers: { authorization: cookies.get("token") },
    })
    .then((res) => {
      setLoading(false);
      return {
        data: res.data,
        success: true,
      };
    })
    .catch((err) => {
      setLoading(false);
      showToast("Error fetching data");
      return {
        success: false,
      };
    });
};

export const getResultOfTest = async (testId) => {
  setLoading(true);
  return axios
    .get(base_url + "tests/result/" + testId, {
      headers: { authorization: cookies.get("token") },
    })
    .then((res) => {
      setLoading(false);
      return {
        data: res.data,
        success: true,
      };
    })
    .catch((err) => {
      setLoading(false);
      showToast("Error fetching data");
      return {
        success: false,
      };
    });
};

export const getLeaderBoard = async (testId) => {
  setLoading(true);
  return axios
    .get(base_url + "api/leaderboard/tests/" + testId, {
      headers: { authorization: cookies.get("token") },
    })
    .then((res) => {
      setLoading(false);
      return {
        data: res.data,
        success: true,
      };
    })
    .catch((err) => {
      setLoading(false);
      showToast("Error fetching data");
      return {
        success: false,
      };
    });
};
export const fetchAttemptedTests = async (lang, level) => {
  setLoading(true);
  return axios
    .get(base_url + "tests/attempted?level_id=" + (parseInt(level) + 5), {
      headers: { authorization: cookies.get("token") },
    })
    .then((res) => {
      setLoading(false);
      return {
        data: res.data,
        success: true,
      };
    })
    .catch((err) => {
      setLoading(false);
      showToast("Error fetching data");
      return {
        success: false,
      };
    });
};

export const fetchTestById = async (testId) => {
  setLoading(true);
  return axios
    .get(base_url + "tests/" + testId, {
      headers: { authorization: cookies.get("token") },
    })
    .then((res) => {
      setLoading(false);
      return {
        data: res.data,
        success: true,
      };
    })
    .catch((err) => {
      setLoading(false);
      showToast("Error fetching data");
      return {
        success: false,
      };
    });
};

export const submitTestSolution = async (submission, test_id) => {
  return axios
    .post(base_url + "tests/submitSolution/" + test_id, submission, {
      headers: { authorization: cookies.get("token") },
    })
    .then((res) => {
      //console.log(res.data)
    })
    .catch((err) => {
      //console.log(err)
    });
};

export const submitTestSolutions = async (submission, test_id) => {
  return axios
    .post(base_url + "tests/submit/" + test_id, submission, {
      headers: { authorization: cookies.get("token") },
    })
    .then((res) => {
      //console.log(res.data)
    })
    .catch((err) => {
      //console.log(err)
    });
};
export const fetchProblemBySerial = (seriesId, serial) => {
  setLoading(true);
  return axios
    .get(base_url + "problemBySerial/" + seriesId + "/" + serial, {
      headers: { authorization: cookies.get("token") },
    })
    .then((res) => {
      setLoading(false);
      return res.data;
    })
    .catch((err) => {
      setLoading(false);
      showToast("Error fetching data");
    });
};

export const fetchProblems = (seriesId) => {
  setLoading(true);
  return axios
    .get(`${base_url}series/${seriesId}`, {
      headers: { authorization: cookies.get("token") },
    })
    .then((res) => {
      setLoading(false);
      return res.data;
    })
    .catch((err) => {
      setLoading(false);
      showToast("Error fetching data");
    });
};

export const submitSolution = (submission) => {
  return axios
    .post(base_url + "submission/submitSolution/", submission, {
      headers: { authorization: cookies.get("token") },
    })
    .then((res) => {
      //console.log(res.data)
    })
    .catch((err) => {
      //console.log(err)
    });
};

export const fetchErroredProblems = (dispatch, req) => {
  return axios
    .get(base_url + "api/submission/unsolved/en", {
      headers: { authorization: cookies.get("token") },
    })
    .then((res) => {
      dispatch(erroredProblemsDispatch(res.data));
    })
    .catch((err) => {
      //console.log(err)
    });
};

const erroredProblemsDispatch = (data) => {
  return {
    type: "UPDATE_ERRORED_PROBLEMS",
    data: data,
  };
};

export const submitFeedback = (feedback) => {
  return axios
    .post(base_url + "feedback/submitFeedback/", feedback, {
      headers: { authorization: cookies.get("token") },
    })
    .then((res) => {
      showToast("Feedback Submitted");
    })
    .catch((err) => {
      //console.log(err)
    });
};
