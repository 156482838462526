import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {createStore} from 'redux'
import allReducers from './reducer'
import {Provider} from 'react-redux'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { usePreview } from 'react-dnd-preview';

import dotenv from 'dotenv';
dotenv.config();

const env = process.env.REACT_APP_ENV;

let base_url;

if (env === 'dev') {
    base_url = process.env.REACT_APP_BASE_URL_LOCAL;
} else if (env === 'prod') {
    base_url = process.env.REACT_APP_BASE_URL_PROD;
} else {
    throw new Error(`Invalid environment: ${env}. Please set REACT_APP_ENV to 'dev' or 'prod'.`);
}

export { base_url };

const store=createStore(allReducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

const MyPreview = () => {

    const {display, itemType, item, style} = usePreview();
    if (!display) {
        return null;
    }
    style['padding']='0.5rem 1rem'
    style['border']='1px dashed #0090ff'
    style['borderRadius']='8px'
    style['color']='#0090ff'
    style['background']='white'
    return <div class="item-list__item" style={style}>{itemType}</div>;
};

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <DndProvider backend={TouchBackend} options={{enableMouseEvents:true}}>
              <App />
              <MyPreview />
          </DndProvider>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
