import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactStoreIndicator from "react-score-indicator";
import { fetchStats } from "../action/profile";
import { base_url } from "..";
import axios from "axios";
import Cookies from "universal-cookie";
import { useState } from "react";
import "../sass/scoreDetails.scss";

const cookies = new Cookies();

export default function ScoreDetails() {
  const stat = useSelector((state) => state.stat);
  const dispatch = useDispatch();
  const [solvedProblems, setSolvedProblems] = useState([]);
  useEffect(() => {
    fetchStats(dispatch);
    fetchProblems();
  }, []);

  const fetchProblems = () => {
    axios
      .get(base_url + "api/score/score-details/", {
        headers: { authorization: cookies.get("token") },
      })
      .then((res) => {
        setSolvedProblems(res.data);
      });
  };
  return (
    <div className="score-details">
      <ReactStoreIndicator
        value={stat.right}
        maxValue={stat.right + stat.wrong}
        lineWidth={12}
      />
      <div className="score-details-problems">
        <div className="score-details-problems-heading">
          <h3>Problem Title</h3>
          <h3>Marks</h3>
        </div>

        {/* {solvedProblems && solvedProblems[0].title} */}

        {solvedProblems.map((data) => (
          <div key={data.problem_id} className="score-details-problems-problem">
            <h3>{data.title}</h3>
            <h3
              style={{
                backgroundColor: data.gained_marks == 0 ? "#e84374" : "#45c881",
              }}
            >
              {data.gained_marks}
            </h3>
          </div>
        ))}
      </div>
    </div>
  );
}
