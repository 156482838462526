import React, {useEffect, useState} from 'react'
import '../../css/series.css'
import '../../css/topics.css'
import {fetchSerieses, fetchTopics} from "../../action/content";
import {Grid} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({

    gridCentering: {

        [theme.breakpoints.down('1280')]: {
            justifyContent: 'center'
        }
    }
}));

const SeriesList=props=>{
    const classes = useStyles();

    const topicID=props.match.params.id
    const lang=props.match.params.lang;
    const level=props.match.params.level;

    const [serieses,setSerieses]=useState([])

    useEffect(()=>{
        fetchSerieses(topicID).then(res=>{
            setSerieses(res)
            //console.log(res)
        })
    },[])

    return(
        <div className={'topics-container'}>
            <div className={'topics-title'}>
                Series
            </div>
            <Grid container spacing={2} className={classes.gridCentering}>
                {
                    serieses.map((series,i)=>{
                        return(
                            <Grid item xs={10} lg ={6} onClick={()=>{props.history.push(`/lang/${lang}/level/${level}/series/${series.series_id}/problem/${1}`)}}>
                                <div className={'series-container'}>
                                    <div className={'series-left-bar'}/>
                                    <div className={'series-body'}>
                                        <div className={'series-top-container'}>
                                            <div className={'series-top-left'}>
                                                <div className={'topic-serial'}>
                                                    {'0'+(i+1)}
                                                </div>
                                                <div className={'topic-title'}>
                                                    {series.name}
                                                </div>
                                            </div>
                                            <div className={'series-top-right'}>
                                                <img src={series.logo}/>
                                            </div>
                                        </div>
                                        <div className={'series-divider'}/>
                                        <div className={'series-bottom-container'}>
                                            {series.topic_name}<br/>{series.nproblem} Problems
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </div>
    )
}

export default SeriesList
