import React, { useEffect, useState } from "react";
import { fetchTestById, submitTestSolutions } from "../../../action/content";
import Problem from "./TestProblem";
import "../../../css/problem-container.css";
import "../../../css/test.css";
import "../../../css/timer.css";
import CountdownTimer from "./CountdownTimer";
import uuid from "react-uuid";
import "../../../css/scoreboard.css";
import TestResult from "./TestResult";
import Confirmation from "./Confirmation";

const ExpiredNotice = (props) => {
  return (
    <div className="expired-notice-container">
      <div className="expired-notice">
        <span>{props.msg}</span>
      </div>
    </div>
  );
};

const TestContainer = (props) => {
  const lang = props.match.params.lang;
  const level = props.match.params.level;
  const [testId, setTestId] = useState(props.match.params.test_id);
  const serial = 1;
  const [serialState, setSerialState] = useState(serial);
  const [problems, setProblems] = useState(null);
  const [test, setTest] = useState(null);
  const [problem, setProblem] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [testTimer, setTestTimer] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [tmpProblems, setTmpProblems] = useState(null);
  const [submissionPending, setSubmissionPending] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [open, setOpen] = useState(false);
  const [hasAttempted, setHasAttempted] = useState(false);
  const saveAnswer = (savedAnswer) => {
    if (savedAnswer !== null) {
      setHasAttempted(true);
      setAnswers(
        answers.map((p) => {
          return {
            problem_id: p.problem_id,
            sub_status:
              p.problem_id === savedAnswer.problem_id
                ? savedAnswer.sub_status
                : p.sub_status,
          };
        })
      );
    }
  };

  const submitSolution = (savedAnswer) => {
    saveAnswer(savedAnswer);
    if (!isSubmitted) {
      // setSubmissionPending(true);
      setOpen(true);
    }
  };

  // const fetchResult = () => {
  //   getResultOfTest(testId).then((res2) => {
  //     if (res2.data.success) {
  //       setScore(res2.data.result.score);
  //       setAnswers(res2.data.result.answers);
  //     }
  //   });
  // };
  const nextProblem = (savedAnswer) => {
    saveAnswer(savedAnswer);
    if (serialState < problems.length) {
      setSerialState(serialState + 1);
    } else {
      setSerialState(1);
    }
  };

  const prevProblem = (savedAnswer) => {
    saveAnswer(savedAnswer);
    if (serialState > 1) {
      setSerialState(serialState - 1);
    } else {
      setSerialState(problems.length);
    }
  };

  useEffect(() => {
    if (submissionPending) {
      // console.log(answers);
      submitTestSolutions(answers, testId).then((res) => {
        // fetchResult();
        setIsSubmitted(true);
      });
      setSubmissionPending(false);
    }
  }, [submissionPending]);

  useEffect(() => {
    if (problems !== null) {
      setAnswers(
        problems.map((p) => {
          return { problem_id: p.problem_id, sub_status: 2 };
        })
      );
    }
  }, [problems]);

  // const interval = setInterval(() => {
  //   if (testTimer !== null && new Date().getTime() >= testTimer) {
  //     setTestId(testId);
  //     setTestTimer(null);
  //   }
  // }, 1000);

  // useEffect(() => {
  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    fetchTestById(testId).then((res) => {
      if (res.success) {
        setTest(res.data.test_details.test);
        setIsSubmitted(res.data.is_submitted);
        if (!res.data.is_submitted) setProblems(res.data.test_details.problems);
        let start = new Date(
          res.data.test_details.test.test_date_time
        ).getTime();
        console.log(
          res.data.test_details.test.test_date_time,
          new Date(res.data.test_details.test.test_date_time)
        );
        let duration = res.data.test_details.test.duration * 60 * 60 * 1000;
        const endTime = start + duration;
        setStartTime(start);
        setTestTimer(endTime);
      }
    });
  }, [testId, serial]);

  // useEffect(() => {
  //   if (
  //     isSubmitted &&
  //     answers.length === 0 &&
  //     new Date().getTime() >= testTimer
  //   ) {
  //     fetchResult();
  //   }
  // }, [isSubmitted]);

  useEffect(() => {
    if (problems !== null) setProblem(problems[serialState - 1]);
  }, [problems, serialState]);

  return (
    <div style={{ padding: "20px" }}>
      {testTimer !== null ? (
        new Date().getTime() < startTime ? (
          <>
            <ExpiredNotice msg="Test starts in" />
            <CountdownTimer targetDate={startTime} flag={"start"} />
          </>
        ) : testTimer > new Date().getTime() && isSubmitted ? (
          <>
            <ExpiredNotice msg="Test submitted" />
            <CountdownTimer targetDate={testTimer} flag={"end"} />
          </>
        ) : testTimer > new Date().getTime() ? (
          <CountdownTimer targetDate={testTimer} flag={"end"} />
        ) : (
          <></>
        )
      ) : (
        <div />
      )}

      {problem !== null &&
      new Date().getTime() > startTime &&
      new Date().getTime() < testTimer &&
      !isSubmitted ? (
        <div>
          <hr className={"stepper-line"} />
          <div className={"stepper-container"}>
            {answers.map((a, i) => {
              if (serialState == i + 1) {
                return <div className={`step-current`}>{i + 1}</div>;
              } else {
                return (
                  <div
                    className={`step-pending ${
                      a.sub_status === 0
                        ? "step-success"
                        : a.sub_status === 1
                        ? "step-success"
                        : ""
                    }`}
                    onClick={() => {
                      setSerialState(i + 1);
                    }}
                  >
                    {i + 1}
                  </div>
                );
              }
            })}
          </div>
          <Problem
            next={nextProblem}
            prev={prevProblem}
            key={uuid()}
            level={level}
            history={props.history}
            problem={problem}
            serial={serial}
            save={saveAnswer}
            submit={submitSolution}
            test={test}
            endTime={testTimer}
          />
          <Confirmation
            open={open}
            setOpen={setOpen}
            onConfirm={() => setSubmissionPending(true)}
            msg={
              hasAttempted ? (
                <></>
              ) : (
                <div style={{ color: "red" }}>
                  {"Warning: No problem is answered"}
                </div>
              )
            }
          />
        </div>
      ) : new Date().getTime() >= testTimer && isSubmitted ? (
        <TestResult testId={testId} />
      ) : (
        <div />
      )}
    </div>
  );
};

export default TestContainer;
