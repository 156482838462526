import React from "react";
import ReactStoreIndicator from "react-score-indicator";
const ScoreBoard = ({ obtained_score, total_score }) => {
  return (
    <>
      <ReactStoreIndicator
        value={obtained_score}
        maxValue={total_score}
        lineWidth={15}
        fadedOpacity={20}
        stepsColors={[
          "#E84374",
          "#e84a45",
          "#e88e45",
          "#e8c545",
          "#c2e845",
          "#50e845",
          "#45e883",
          "#45C881",
        ]}
      />
      {/* <ReactStoreIndicator
        value={obtained_score}
        maxValue={total_score}
        lineWidth={15}
        fadedOpacity={100}
      /> */}
    </>
  );
};

export default ScoreBoard;
