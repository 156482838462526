import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProblemsContainer from "../../components/Containers/ProblemsContainer";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Select, MenuItem } from "@material-ui/core";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "30vh",
    },
  },
};

const SelectionField = (props) => {
  return (
    <FormControl className="input-field" variant="outlined" size="small">
      <InputLabel htmlFor="outlined-adornment" className="input-label">
        {props.label}
      </InputLabel>
      <Select
        required
        id="outlined-adornment"
        className="outlined-input"
        value={props.value}
        onChange={(e) => props.setValue(e.target.value)}
        input={<OutlinedInput label={props.label} />}
        MenuProps={MenuProps}
      >
        {props.list.map((value) => (
          <MenuItem
            key={value}
            value={value}
            // sx={{ height: "2rem" }}
            // style={getStyles(name, personName, theme)}
          >
            {value == "DESC"
              ? "Descending Submission Time"
              : "Ascending Submission Time"}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
const UnsolvedProblems = (props) => {
  const list = useSelector((state) => state.erroredProblems);
  const [unsolvedList, setUnsolvedList] = useState(list);
  const [order, setOrder] = useState("DESC");

  useEffect(() => {
    setOrder("DESC");
  }, [unsolvedList, props]);
  useEffect(() => {
    if (unsolvedList != null) {
      if (order == "ASC") {
        unsolvedList.sort((a, b) =>
          a.last_submission_time < b.last_submission_time ? 1 : -1
        );
      } else {
        unsolvedList.sort((a, b) =>
          a.last_submission_time > b.last_submission_time ? 1 : -1
        );
      }
    }
  }, [order]);
  return (
    <div className={"topics-container"}>
      <div className="hbox" style={{ justifyContent: "space-between" }}>
        <div className={"topics-title"}>Unsolved Problems</div>
        <SelectionField
          value={order}
          setValue={setOrder}
          list={["ASC", "DESC"]}
        />
      </div>

      <ProblemsContainer list={unsolvedList === null ? null : unsolvedList} />
    </div>
  );
};

export default UnsolvedProblems;
