import React from "react";
import ProblemCard from "../Cards/ProblemCard";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  gridCentering: {
    [theme.breakpoints.down("1280")]: {
      justifyContent: "center",
    },
  },
}));

const ProblemsContainer = ({ list }) => {
  const classes = useStyles();
  return list !== null && list.length > 0 ? (
    <Grid container spacing={2} className={classes.gridCentering}>
      {list.map((problem, idx) => {
        return <ProblemCard problem={problem} serial={idx} />;
      })}
    </Grid>
  ) : (
    <div />
  );
};

export default ProblemsContainer;
