import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
const Confirmation = ({ open, setOpen, onConfirm, msg }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {msg}
        <div>Are you sure you want to continue?</div>
      </DialogTitle>
      <DialogActions>
        <Button
          onClick={handleClose}
          //   className="red-button"
          //   color="inherit"
          variant="contained"
        >
          <CloseIcon />
        </Button>
        <Button
          onClick={() => {
            handleClose();
            onConfirm();
          }}
          //   className="blue-button"
          color="secondary"
          variant="contained"
          autoFocus
        >
          <DoneIcon />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirmation;
