import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import Nav from "./Nav";

import laptop_curve_bg from "./img/laptop curve bg.svg";
import laptop_img from "./img/laptop.svg";
import learners_img from "./img/learners.svg";
import problems_img from "./img/problems.svg";
import institutions_img from "./img/institutions.svg";


import TopCarouselCard2 from "./TopCarouselCard2";
import TopCarouselCard1 from "./TopCarouselCard1";

import Middlebody from "./MiddleBody";


const TopBody = props => {
  return (
    <div className="homepage-topbody">
      <Nav  history={props.history} />

      
      <div className="homepage-topbody-hero">
        <div className="homepage-topbody-hero-text">
          <h2>
            Make Learning{" "}
            <span className="homepage-topbody-hero-text-fun">FUN</span> with{" "}
            <span className="homepage-topbody-hero-text-brainlytic">
              Brainlytic
            </span>
          </h2>
          <p>An interactive platfrom to explore the beauty of mathematical and logical problem solving.</p>
          <Carousel
            autoPlay
            interval={4000}
            infiniteLoop
            stopOnHover
            showArrows={false}
            showStatus={false}
            swipeable
            emulateTouch
            
          >
            <div>
              <TopCarouselCard1 />
            </div>
            <div>
              <TopCarouselCard2 />
            </div>
          </Carousel>

          <p onClick={() => {
            props.history.push("/auth/register");
          }} className="homepage-topbody-hero-text-button">Get Started</p>
        </div>
        <div className="homepage-topbody-hero-img">
          <img src={laptop_img} alt="" />
        </div>
      </div>
      {/* <div className="homepage-topbody-cards">
        <div className="homepage-topbody-cards-single">
          <img src={learners_img} alt="" />
          <div className="homepage-topbody-cards-single-text">
            <h3>1000+</h3>
            <p>Learners</p>
          </div>
        </div>
        <div className="homepage-topbody-cards-single">
          <img src={problems_img} alt="" />
          <div className="homepage-topbody-cards-single-text">
            <h3>250+</h3>

            <p>
              Interactive <span>Problems</span>
            </p>
          </div>
        </div>
        <div className="homepage-topbody-cards-single">
          <img src={institutions_img} alt="" />
          <div className="homepage-topbody-cards-single-text">
            <h3>20+</h3>
            <p>Institutions</p>
          </div>
        </div>
      </div> */}

      
      <Middlebody/>
      
    
    </div>
  );
};

export default TopBody;
