import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "../../css/problem.css";
import fire_icon from "../../assets/fire-icon.png";
import author_icon from "../../assets/author.png";
import bulb_icon from "../../assets/bulb-white.png";
import MDEditor from "@uiw/react-md-editor";
import Paper from "@material-ui/core/Paper";
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
  useTheme,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { showToast } from "../../App";
import { isMobile } from "react-device-detect";
import Exclusion from "./question/Exclusion";
import Grouping from "./question/Grouping";
import Rearranging from "./question/Rearranging";
import Matchsticks from "./question/Matchsticks";
import Venn from "./question/Venn";
import DragAndDrop from "./question/DragAndDrop";
import DragAndDropGrid from "./question/DragAndDropGrid";
import MCQ from "./question/MCQ";
import Text from "./question/Text";
import { submitFeedback, submitSolution } from "../../action/content";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Answer from "./Answer";
import Fab from "@material-ui/core/Fab";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";

import StarRatings from "react-star-ratings";
import TextField from "@material-ui/core/TextField";

import { useSelector, useDispatch } from "react-redux";
import { statUpdate } from "../../action/profile";

import celebration from "../../assets/animations/celebration.gif";
import oops from "../../assets/animations/oops.gif";
import { MathJax, MathJaxContext } from "better-react-mathjax";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  stepperRoot: {
    width: "100%",
  },
  canvasPaper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,

  content: {
    flexGrow: 1,
    padding: theme.spacing(2, 1),
  },
  root1: {
    height: "100%",
  },
  eliminationGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  margin: {
    margin: theme.spacing(0.5),
    position: "fixed",
    bottom: 10,
    right: 10,
    zIndex: 10000,
  },
}));

const myTheme = createMuiTheme({
  palette: {
    text: {
      default: "#fff",
    },
    textColor: "#fff",
    primary: {
      main: "#55b5ff",
      contrastText: "#fff",
    },
  },
  stepper: {
    iconColor: "green",
    textColor: "white", // or logic to change color
  },
});

const Problem = (props) => {
  const dispatch = useDispatch();
  const stat = useSelector((state) => state.stat);

  const classes = useStyles();
  const [verdict, setVerdict] = useState(false);
  const [prompt, setPrompt] = useState(false);
  const [explanation, setExplanation] = useState(false);
  const [rating, setRating] = useState(0);
  const [hintState, setHintState] = useState(-1);

  const commentRef = useRef();

  const [hintDialog, setHintDialog] = useState(false);

  const [feedbackDialog, setFeedbackdialog] = useState(false);

  const profile = {};

  const feedbackCheck = () => {
    // checkFeedback(props.data.problem_id,profile.user_id).then(res=>{
    //     setFeedbackdialog(!res.feedbackexists)
    // })
  };

  const feedbackSubmit = () => {
    if (rating === 0) window.alert("please rate 1~3");
    else {
      var comment = commentRef.current.value;
      var feedback = {
        problem_id: props.problem.problem_id,
        user_id: profile.user_id,
        feedback: rating,
      };
      if (comment.trim().length > 0) feedback["comment"] = comment.trim();

      submitFeedback(feedback);
      setFeedbackdialog(false);
    }
  };

  const changeRating = (newRating) => {
    setRating(newRating);
  };

  useEffect(() => {
    console.log(props.problem)
  }, []);

  

  // console.log('hi')
  //console.log(props.data)

  const evaluate = () => {
    var verd = false;

    //console.log(props.data)

    if (props.problem.data.data.type !== "interactive") {
      if (questionRef.current.isValid()) {
        setVerdict(questionRef.current.getVerdict());
        setPrompt(true);
        verd = questionRef.current.getVerdict();
      } else showToast("Enter Your Answer");
    } else {
      if (interactiveRef.current.isValid()) {
        setVerdict(interactiveRef.current.getVerdict());
        setPrompt(true);
        verd = interactiveRef.current.getVerdict();
      } else showToast("Enter Your Answer");
    }

    var status = 0;
    if (verd) status = 1;

    //props.update(props.problem.problem_id,status)

    submitSolution({
      problem_id: props.problem.problem_id,
      user_id: profile.user_id,
      sub_status: status,
    });

    var st = stat;
    if (verd) st.right = st.right + 1;
    else st.wrong = st.wrong + 1;
    //console.log(st)
    statUpdate(dispatch, st);

    // if(!verd){
    //     if(erroredProblems!==null){
    //         var isIn=false
    //         erroredProblems.problemlist.map(eP=>{
    //             if(eP.problem_id===props.data.problem_id)
    //                 isIn = true
    //         })
    //         if(!isIn){
    //             var list=[props.data]
    //             erroredProblems.problemlist.map(eP=>{
    //                 list.push(eP)
    //             })
    //             setErroredProblems(dispatch, {
    //                 problemlist:list
    //             })
    //         }
    //     }else
    //         erroredProblems.push(props.data)
    // }

    //feedbackCheck()
  };

  var interactiveRef = useRef();
  var questionRef = useRef();

  const problem = props.problem;

  return (
    <div>
      {props.problem.data !== undefined &&
      "hint" in props.problem.data &&
      Array.isArray(props.problem.data.hint) &&
      props.problem.data.hint.length > 0 ? (
        <Fab
          onClick={() => {
            setHintDialog(true);
          }}
          color="secondary"
          aria-label="add"
          className={classes.margin}
        >
          <WbIncandescentIcon />
        </Fab>
      ) : (
        <div />
      )}

      <Dialog
        open={hintDialog}
        onClose={() => {
          setHintDialog(false);
        }}
      >
        <DialogTitle>Hint</DialogTitle>
        <DialogContent>
          {props.problem.data !== undefined &&
          "hint" in props.problem.data &&
          Array.isArray(props.problem.data.hint) ? (
            <div>
              {props.problem.data.hint.map((h, i) => {
                return (
                  <div>
                    <Typography>Hint {i + 1} :</Typography>
                    <center>
                      <div className="hintContainer">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setHintState(i);
                          }}
                          src={require("../../assets/icons/blur.png")}
                          width={"100%"}
                        />
                      </div>
                    </center>
                  </div>
                );
              })}
            </div>
          ) : (
            <div />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setHintDialog(false);
            }}
          >
            Got it
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={prompt}
        onClose={() => {
          setPrompt(false);
        }}
      >
        <DialogTitle>
          {verdict ? <div>Congratulations !</div> : <div>It's Ok</div>}
        </DialogTitle>
        <DialogContent>
          <center>
            <img
              style={{ height: "200px", width: "200px" }}
              src={verdict ? celebration : oops}
            />
            <Typography style={{ marginTop: "10px" }}>
              {verdict ? (
                <div>Your answer is correct !!!</div>
              ) : (
                <div>Your answer is incorrect.</div>
              )}
            </Typography>
            {verdict ? (
              <div>
                <Button
                  style={{ marginTop: "10px" }}
                  fullWidth
                  variant="outlined"
                  onClick={() => {
                    setExplanation(true);
                  }}
                  color="secondary"
                >
                  Show Explanation
                </Button>
                <Button
                  style={{ marginTop: "10px" }}
                  fullWidth
                  variant="outlined"
                  onClick={() => {
                    setPrompt(false);
                    props.next();
                  }}
                  color="primary"
                >
                  Go to next problem
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  style={{ marginTop: "10px" }}
                  fullWidth
                  variant="outlined"
                  onClick={() => {
                    setExplanation(true);
                  }}
                  color="secondary"
                >
                  View Solution
                </Button>
                <Button
                  style={{ marginTop: "10px" }}
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setPrompt(false);
                  }}
                >
                  Try Again
                </Button>
              </div>
            )}
          </center>
        </DialogContent>

        <Dialog
          open={explanation}
          onClose={() => {
            setExplanation(false);
          }}
        >
          <DialogTitle>Solution</DialogTitle>
          <DialogContent>
            <Typography>
              <Answer
                interactive={props.problem.data.type}
                data={props.problem.data.data}
              />
            </Typography>
            <br />
            <u>Explanation :</u>
            <MDEditor.Markdown source={props.problem.data.explanation} />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => {
                setExplanation(false);
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>

      <Dialog open={feedbackDialog}>
        <DialogTitle>Feedback</DialogTitle>
        <DialogContent>
          <center>
            <StarRatings
              rating={rating}
              starRatedColor="#0090ff"
              changeRating={changeRating}
              numberOfStars={3}
              name="rating"
            />
            <Typography style={{ marginTop: "10px", color: "#888888" }}>
              1 Star : Normal Problem
              <br />
              2 Stars : Good Problem
              <br />3 Stars : Excellent Problem
            </Typography>
          </center>
          <TextField
            inputRef={commentRef}
            style={{ marginTop: "10px" }}
            label="comment (if any)"
            variant="outlined"
            multiline
            rows={2}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
        
              

              setFeedbackdialog(true);

            }}
          >
            Skip
          </Button>
          <Button color="primary" onClick={feedbackSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <div className={"problem-title"}>{problem.title}</div>
      {problem.associated_problem_id ? (
        <p
          style={{
            color: "#3d7eff",
            fontWeight: 700,
            border: "2px solid",
            width: "180px",
            margin: "16px 0px",
            fontSize: "14px",
            borderRadius: "12px",
            padding: "1px 4px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Bangla Available
        </p>
      ) : (
        ""
      )}

      <div className={"problem-level-container"}>
        <div className={"problem-level-icon"}>
          <img src={fire_icon} />
        </div>
        <div className={"problem-level-level"}>Level {props.level}</div>
       
        <div className={"problem-author-icon"}>
          <img  style={{marginLeft:"10px"}} src={author_icon} />
        </div>
        <div className={"problem-level-author"}> {problem.author_name}</div>

      </div>
      {/* <div className={"problem-author-container"}>
        <div className={"problem-author-icon"}>
          <img src={author_icon} />
        </div>
        <div className={"problem-level-author"}> Rabib </div>
        </div> */}
      <div className={"problem-body"}>
        <MDEditor.Markdown source={props.problem.data.description} />
        {"latex" in props.problem.data &&
        props.problem.data.latex.length > 0 ? (
          <MathJaxContext>
            <MathJax>
              <div className={"latexContainer"}>{props.problem.data.latex}</div>
            </MathJax>
          </MathJaxContext>
        ) : (
          <div />
        )}
        <MDEditor.Markdown source={props.problem.data.statement} />
      </div>
      <div className={"problem-statement"}>
        <img src={bulb_icon} />
        Provide the answer below :
      </div>
      <Paper style={{ padding: "15px" }}>
        <Interactive ref={interactiveRef} data={props.problem.data} />
        <Question ref={questionRef} data={props.problem.data} />
        <center>
          <MuiThemeProvider theme={myTheme}>
            <Button
              style={{ marginTop: "25px" }}
              variant="contained"
              color="primary"
              onClick={evaluate}
            >
              Submit
            </Button>
          </MuiThemeProvider>
  
  {props.nProblem>props.serial?
        <Button
  style={{
    marginTop: "25px",
    marginLeft: "10px",
    borderColor: "gray", // Set the outline color to green
    color: "green" // Set text color to match the outline (optional)
  }}
  variant="outlined"

  onClick={() => {
    window.location.href =`/lang/${props.problem.data.lang}/level/${props.level}/series/${props.problem.series_id}/problem/${(Number(props.serial)+1)}`;
          
  }}
>
  Skip
</Button>:null
}
          <Button
            style={{ marginTop: "25px", marginLeft: "10px" }}
            variant="outlined"
            color="primary"
            onClick={() => {
              setFeedbackdialog(true);
            }}
          >
            Feedback
          </Button>
          
        </center>
      </Paper>
    </div>
  );
};

export const Interactive = forwardRef((props, ref) => {
  const interactiveRef = useRef();

  useImperativeHandle(ref, () => ({
    isValid() {
      return interactiveRef.current.isValid();
    },
    getVerdict() {
      return interactiveRef.current.getVerdict();
    },
  }));

  //console.log(props.data)
  if (props.data.type === "exclusion_grid") {
    //console.log(props.data)
    var data = {
      rows: props.data.data.rowHeading,
      cols: props.data.data.columnHeading,
      data: props.data.data.cell,
      state: props.data.data.initialState,
      sol: props.data.data.data.answer,
    };
    return <Exclusion ref={interactiveRef} data={data} />;
  } else if (props.data.type === "dragAndDrop-1") {
    var containers = [],
      schema = [];
    props.data.data.containers.map((container) => {
      containers.push(container.label);
      schema.push([]);
    });
    var tmpData = {
      containers: containers,
      items: props.data.data.unselected,
      schema: schema,
      answer: props.data.data.data.answer,
    };
    //console.log('hi')
    //console.log(tmpData)
    return <Grouping ref={interactiveRef} data={tmpData} />;
  } else if (props.data.type === "rearrange") {
    var tmpData = {
      items: props.data.data.fields,
      sol: props.data.data.data.answer,
    };
    return <Rearranging ref={interactiveRef} data={tmpData} />;
  } else if (props.data.type === "matchstick") {
    //console.log(props.data)
    return (
      <Matchsticks
        containerId="question"
        ref={interactiveRef}
        editor={false}
        data={props.data.data}
      />
    );
  } else if (props.data.type === "venn") {
    //console.log(props.data)
    return (
      <Venn
        containerId="question"
        ref={interactiveRef}
        editor={false}
        data={props.data.data}
      />
    );
  } else if (props.data.type === "dragAndDrop-2") {
    //console.log(props.data)
    if (isMobile) {
      return (
        <div style={{ width: "100%" }}>
          <DragAndDrop
            containerId="question"
            ref={interactiveRef}
            data={props.data.questionnaire}
          />
        </div>
      );
    } else
      return (
        <center>
          <div style={{ width: "50%" }}>
            <DragAndDrop
              containerId="question"
              ref={interactiveRef}
              data={props.data.questionnaire}
            />
          </div>
        </center>
      );
  } else if (props.data.type === "dragAndDrop-3-Grid") {
    var containers = [],
      schema = [];
    props.data.data.containers.map((container) => {
      containers.push(container.label);
      schema.push([]);
    });
    console.log(props.data);
    var tmpData = {
      containers: containers,
      items: props.data.data.unselected,
      schema: schema,
      answer: props.data.data.data.answer,
      active: props.data.data.active,
      nRows: props.data.data.nRows,
      nCols: props.data.data.nCols,
    };
    //console.log('hi')
    //console.log(tmpData)
    return <DragAndDropGrid ref={interactiveRef} data={tmpData} />;
  } else return <div />;
});

export const Question = forwardRef((props, ref) => {
  const questionRef = useRef();

  //console.log(props.data)

  useImperativeHandle(ref, () => ({
    isValid() {
      return questionRef.current.isValid();
    },
    getVerdict() {
      return questionRef.current.getVerdict();
    },
  }));

  //console.log(props.data)

  if (props.data.data.type === "mcq")
    return <MCQ ref={questionRef} data={props.data.data} />;
  else if (props.data.data.type === "text")
    return <Text ref={questionRef} data={props.data.data} />;
  else return <div />;
});

export default Problem;
