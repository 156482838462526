import React, {useEffect} from 'react'
import {setBnLang, setEnLang} from "../../action/lang";
import {useDispatch} from "react-redux";

const En=props=>{

    const dispatch = useDispatch();

    useEffect(()=>{
        setEnLang(dispatch)
    })

    return(
        <div/>
    )
}

export default En