import React from "react";

import Carousel2 from "./Carousel2";
import Footer from "./Footer";

import checkmark_img from "./img/tick.svg";
import interactive_img from "./img/Interactive.svg";
import certificate_img from "./img/certificate.svg";
import crossplatfrom_img from "./img/cross platform.svg";
import track_progress_img from "./img/track progress.svg";
import leaderboard_img from "./img/leaderboard.svg";
import level_system_img from "./img/Level system.svg";
import recommendation_img from "./img/recommendation.svg";
import retry_img from "./img/retry.svg";
import trophy_img from "./img/trophy.svg";



const Middlebody = () => {

  return (
    <div className="homepage-middlebody" id="features">
      <div className="homepage-middlebody-cards" >
        <div className="homepage-middlebody-cards-singleodd">
          <div className="homepage-middlebody-cards-singleodd-img">
            <img src={interactive_img} alt="" />
          </div>
          <div className="homepage-middlebody-cards-singleodd-text">
            <h2>Interactivity</h2>
            
            <div className="homepage-middlebody-cards-singleodd-text-checkmarks">
              <div className="homepage-middlebody-cards-singleodd-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>A variety of interactive interfaces.</p>
              </div>
              <div className="homepage-middlebody-cards-singleodd-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Learners can explore different possibilities for a better understanding of the problems.</p>
              </div>
              <div className="homepage-middlebody-cards-singleodd-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Kids enjoy the feeling of gamification and can see instant progress/performance reports.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="homepage-middlebody-cards-singleeven">
          <div className="homepage-middlebody-cards-singleeven-img">
            <img src={level_system_img} alt="" />
          </div>
          <div className="homepage-middlebody-cards-singleeven-text">
            <h2>Accessible Level System</h2>
            
            <div className="homepage-middlebody-cards-singleeven-text-checkmarks">
              <div className="homepage-middlebody-cards-singleeven-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Flexible access for students</p>
              </div>
              <div className="homepage-middlebody-cards-singleeven-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Not Grade, rather aptitude and problem solving skill matters</p>
              </div>
              <div className="homepage-middlebody-cards-singleeven-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Students can start at any level and move upward/downward to explore harder/easier problems</p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="homepage-middlebody-cards-singleodd">
          <div className="homepage-middlebody-cards-singleodd-img">
            <img src={recommendation_img} alt="" />
          </div>
          <div className="homepage-middlebody-cards-singleodd-text">
            <h2>Problem Recommendation</h2>
            <p>Designed for mental growth</p>
            <div className="homepage-middlebody-cards-singleodd-text-checkmarks">
              <div className="homepage-middlebody-cards-singleodd-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Lorem ipsum dolor sit</p>
              </div>
              <div className="homepage-middlebody-cards-singleodd-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>2nd point</p>
              </div>
              <div className="homepage-middlebody-cards-singleodd-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Lorem ipsum dolor sit</p>
              </div>
            </div>
          </div>
           </div> */}
        <div className="homepage-middlebody-cards-singleodd">
          <div className="homepage-middlebody-cards-singleodd-img">
            <img src={track_progress_img} alt="" />
          </div>
          <div className="homepage-middlebody-cards-singleodd-text">
            <h2>Track Your Progress</h2>
            
            <div className="homepage-middlebody-cards-singleodd-text-checkmarks">
              <div className="homepage-middlebody-cards-singleodd-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Can see one’s own record of problem solving</p>
              </div>
              <div className="homepage-middlebody-cards-singleodd-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Live updates of number of problems solved & retries</p>
              </div>
              <div className="homepage-middlebody-cards-singleodd-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Can gain an idea of one’s own weak/strong points</p>
              </div>
            </div>
          </div>
        </div>
       
        <div className="homepage-middlebody-cards-singleeven">
          <div className="homepage-middlebody-cards-singleeven-img-withoutbg">
            <img src={retry_img} alt="" />
          </div>
          <div className="homepage-middlebody-cards-singleeven-text">
            <h2>Learning-Friendly Retry feature</h2>
          
            <div className="homepage-middlebody-cards-singleeven-text-checkmarks">
              <div className="homepage-middlebody-cards-singleeven-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Easy access to previously failed attempts</p>
              </div>
              <div className="homepage-middlebody-cards-singleeven-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Rewards fewer number of attempts needed for a correct solution</p>
              </div>
              <div className="homepage-middlebody-cards-singleeven-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p> Comes with hints to guide learners if they feel stuck</p>
              </div>
              <div className="homepage-middlebody-cards-singleeven-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>  Learn from mistakes by repeated practice</p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="homepage-middlebody-cards-singleeven">
          <div className="homepage-middlebody-cards-singleeven-img-withoutbg">
            <img src={trophy_img} alt="" />
          </div>
          <div className="homepage-middlebody-cards-singleeven-text">
            <h2>Get Awards</h2>
            <p>Designed for mental growth</p>
            <div className="homepage-middlebody-cards-singleeven-text-checkmarks">
              <div className="homepage-middlebody-cards-singleeven-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Lorem ipsum dolor sit</p>
              </div>
              <div className="homepage-middlebody-cards-singleeven-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>2nd point</p>
              </div>
              <div className="homepage-middlebody-cards-singleeven-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Lorem ipsum dolor sit</p>
              </div>
            </div>
          </div>
        </div>

        <div className="homepage-middlebody-cards-singleodd">
          <div className="homepage-middlebody-cards-singleodd-img-withoutbg">
            <img src={certificate_img} alt="" />
          </div>
          <div className="homepage-middlebody-cards-singleodd-text">
            <h2>Get Certified</h2>
            <p>Designed for mental growth</p>
            <div className="homepage-middlebody-cards-singleodd-text-checkmarks">
              <div className="homepage-middlebody-cards-singleodd-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Lorem ipsum dolor sit</p>
              </div>
              <div className="homepage-middlebody-cards-singleodd-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>2nd point</p>
              </div>
              <div className="homepage-middlebody-cards-singleodd-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Lorem ipsum dolor sit</p>
              </div>
            </div>
          </div>
        </div>
        <div className="homepage-middlebody-cards-singleeven">
          <div className="homepage-middlebody-cards-singleeven-img">
            <img src={leaderboard_img} alt="" />
          </div>
          <div className="homepage-middlebody-cards-singleeven-text">
            <h2>Leaderboard</h2>
            <p>Designed for mental growth</p>
            <div className="homepage-middlebody-cards-singleeven-text-checkmarks">
              <div className="homepage-middlebody-cards-singleeven-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Lorem ipsum dolor sit</p>
              </div>
              <div className="homepage-middlebody-cards-singleeven-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>2nd point</p>
              </div>
              <div className="homepage-middlebody-cards-singleeven-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Lorem ipsum dolor sit</p>
              </div>
            </div>
          </div>
        </div>

        <div className="homepage-middlebody-cards-singleodd">
          <div className="homepage-middlebody-cards-singleodd-img">
            <img src={crossplatfrom_img} alt="" />
          </div>
          <div className="homepage-middlebody-cards-singleodd-text">
            <h2>Cross Platfrom</h2>
            <p>Designed for mental growth</p>
            <div className="homepage-middlebody-cards-singleodd-text-checkmarks">
              <div className="homepage-middlebody-cards-singleodd-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Lorem ipsum dolor sit</p>
              </div>
              <div className="homepage-middlebody-cards-singleodd-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>2nd point</p>
              </div>
              <div className="homepage-middlebody-cards-singleodd-text-checkmarks-checkmarktext">
                <img src={checkmark_img} alt="" />
                <p>Lorem ipsum dolor sit</p>
              </div>
            </div>
          </div>
        </div> */}
    
      </div>
   
      <div className="footerandcarousel">

        <Carousel2 />

      </div>
    </div>
  );
};

export default Middlebody;
