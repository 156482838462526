import React, { useEffect, useRef, useState } from "react";
import MDEditor from "@uiw/react-md-editor";
import Paper from "@material-ui/core/Paper";
import "../../../css/problem.css";
import fire_icon from "../../../assets/fire-icon.png";
import bulb_icon from "../../../assets/bulb-white.png";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { showToast } from "../../../App";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { Question, Interactive } from "../Problem";
const myTheme = createTheme({
  palette: {
    text: {
      default: "#fff",
    },
    textColor: "#fff",
    primary: {
      main: "#55b5ff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#11b511",
      contrastText: "#fff",
    },
  },
  stepper: {
    iconColor: "green",
    textColor: "white", // or logic to change color
  },
});

const TestProblem = (props) => {
  var interactiveRef = useRef();
  var questionRef = useRef();
  const problem = props.problem;
  const [savedAnswer, setSavedAnswer] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (props.endTime !== null && new Date().getTime() >= props.endTime) {
        props.submit(savedAnswer);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const evaluate = () => {
    var verd = false;
    if (props.problem.data.data.type !== "interactive") {
      if (questionRef.current.isValid()) {
        verd = questionRef.current.getVerdict();
      } else showToast("Enter Your Answer");
    } else {
      if (interactiveRef.current.isValid()) {
        verd = interactiveRef.current.getVerdict();
      } else showToast("Enter Your Answer");
    }

    var status = 0;
    if (verd) status = 1;

    setSavedAnswer({
      problem_id: props.problem.problem_id,
      sub_status: status,
    });
    showToast("Answer saved");
  };

  return (
    <div>
      <div
        className="hbox"
        style={{
          justifyContent: "space-between",
          alignItem: "center",
        }}
      >
        <div className={"problem-title"}>{problem.title}</div>
        <div
          className={"flex-center"}
          style={{ fontSize: "1.3rem", color: "red" }}
        >
          {problem.marks} marks
        </div>
      </div>
      <div className={"problem-level-container"}>
        <div className={"problem-level-icon"}>
          <img src={fire_icon} />
        </div>
        <div className={"problem-level-level"}>Level {props.level}</div>
      </div>

      <div className={"problem-body"}>
        <MDEditor.Markdown source={props.problem.data.description} />
        {"latex" in props.problem.data &&
        props.problem.data.latex.length > 0 ? (
          <MathJaxContext>
            <MathJax>
              <div className={"latexContainer"}>{props.problem.data.latex}</div>
            </MathJax>
          </MathJaxContext>
        ) : (
          <div />
        )}
        <MDEditor.Markdown source={props.problem.data.statement} />
      </div>

      <div className={"problem-statement"}>
        <img src={bulb_icon} />
        Provide the answer below :
      </div>
      <Paper style={{ padding: "15px" }}>
        <Interactive ref={interactiveRef} data={props.problem.data} />
        <Question ref={questionRef} data={props.problem.data} />
        <center>
          <Button
            style={{ marginTop: "25px", marginRight: "10px" }}
            variant="outlined"
            color="primary"
            onClick={() => {
              props.prev(savedAnswer);
            }}
          >
            Previous
          </Button>
          <MuiThemeProvider theme={myTheme}>
            <Button
              style={{ marginTop: "25px" }}
              variant="contained"
              color="primary"
              onClick={evaluate}
            >
              Save answer
            </Button>
          </MuiThemeProvider>
          <Button
            style={{ marginTop: "25px", marginLeft: "10px" }}
            variant="outlined"
            color="primary"
            onClick={() => {
              props.next(savedAnswer);
            }}
          >
            Next
          </Button>
          <MuiThemeProvider theme={myTheme}>
            <Button
              style={{ marginTop: "25px", marginLeft: "10px" }}
              variant="contained"
              color="secondary"
              onClick={() => {
                // console.log("->", savedAnswer);
                props.submit(savedAnswer);
                // setOpen(true);
              }}
            >
              Submit
            </Button>
          </MuiThemeProvider>
        </center>
      </Paper>
    </div>
  );
};

export default TestProblem;
