import Cookies from "universal-cookie";
import ProgressApi from "../api/progressApi";
import Controller from "./base";

class ProgressController extends Controller {
  progressApi = new ProgressApi();
  getAllSolvedProblems = async () => {
    const res = await this.progressApi.getAllSolvedProblems();
    return res;
  };
  getAllSubmissions = async () => {
    const res = await this.progressApi.getAllSubmissions();
    return res;
  };
}
export default ProgressController;
