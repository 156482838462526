import "../../css/footer.css";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActigons from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import Slide from "@material-ui/core/Slide";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
function Footer() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  return (
    <div className="footer">
      <div>
        <div className="left">
          <img
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              marginLeft: "10px",
              marginTop: "30px",
            }}
            src="https://buet-edu-1.s3.amazonaws.com/auto_upload/I97my9P8SJhfh4WrtV4CTc8xNo92/1628790758249.svg?fbclid=IwAR0XHVXe0MBvbLZhzabR65Q1hiGrLrzGYcTie9HmGUEiFqhdyAzNz6yXf9A"
          />
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            {" "}
            <p onClick={() => window.scrollTo(0, 0)}>Brainlytic</p>
          </Link>
        </div>
        <div className="left-down">
          <p>
            An interactive platfrom to explore the beauty of mathematical and
            logical problem solving.
          </p>
          {/* <div className="media">
            <img
              style={{
                width: "80px",
                height: "100px",
                borderRadius: "50%",
                
              }}
              src="https://buet-edu-1.s3.amazonaws.com/auto_upload/I97my9P8SJhfh4WrtV4CTc8xNo92/1626368617311.svg"
            />

            <img
              style={{
                width: "80px",
                height: "100px",
                borderRadius: "50%",
                
              }}
              src="https://buet-edu-1.s3.amazonaws.com/auto_upload/I97my9P8SJhfh4WrtV4CTc8xNo92/1626368385998.svg"
            />
            <img
              style={{
                width: "80px",
                height: "100px",
                borderRadius: "50%",
                
              }}
              src="https://buet-edu-1.s3.amazonaws.com/auto_upload/I97my9P8SJhfh4WrtV4CTc8xNo92/1626367269503.svg"
            />
          </div> */}
        </div>
      </div>

      <div className="rightMost">
        <p>Engage</p>

        <h5 onClick={handleClickOpen} style={{ cursor: "pointer" }}>
          FAQ
        </h5>
        <Link
          to="/about"
          style={{ cursor: "pointer" }}
          onClick={() => window.scrollTo(0, 0)}
        >
          About Us
        </Link>
        <h5 onClick={() => window.scrollTo(0, 0)}>
          <Link
            to="/privacy"
            style={{ textDecoration: "none", color: "white" }}
          >
            Privacy Policy
          </Link>
        </h5>
      </div>
      <Dialog
        fullScreen
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              FAQ
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent dividers>
          <Typography gutterBottom>
            <b>
              <u>Customer FAQs:</u>
            </b>
            <br />
            <b>
              <u>1.How do I get started?</u>
            </b>
            <br />
            <br />
            <b>Ans: </b> Just download the app from the{" "}
            <a href="https://play.google.com/store/apps/details?id=org.brainlytic.app">
              Google Play Store{" "}
            </a>{" "}
            if you’re on Android or visit our{" "}
            <a href="https://www.brainlytic.org/?fbclid=IwAR0IiSyXqO9n4ti7Ck61qwrgl18z65qQDZ-wR8NwgpzOHjziYehiBTpYexI">
              website
            </a>{" "}
            if you’re on a PC/Laptop. Just use a gmail account/ Contact number
            to login, and start exploring.
          </Typography>
          <Typography gutterBottom>
            <b>
              <u>2.Does it always require an internet connection to work?</u>
            </b>
            <br />
            <br />
            <b>Ans:</b> No, it doesn’t. Our app has an “Offline” feature that
            works as follows:. When you are online, you can “save” some
            problems. Then you can solve these problems when you are offline.
            But to update your progress, you need to reconnect to the internet
            at a later time. But we suggest staying connected to the internet to
            get the full experience.
          </Typography>
          <Typography gutterBottom>
            <b>
              <u>3. What data is being collected and how is it being used?</u>
            </b>
            <br />
            <br />
            <b>Ans:</b> We are collecting just the Gmail Id, User-name and
            Grade/Class. This is for giving every user a unique account with
            personalized progress-tracking and problem suggestions. This data is
            only being used for internal analytics and is not being shared with
            any third party organizations.
          </Typography>
          <Typography gutterBottom>
            <b>
              <u>
                4.What do I do if I find a seemingly incorrect solution or a
                bug?
              </u>
            </b>
            <br />
            <br />
            <b>Ans:</b> We will try our best to avoid such inconvenience, but if
            any user comes across this, they can send us feedback directly from
            inside the app. We check these feedback regularly and update our app
            accordingly.
          </Typography>
          <Typography gutterBottom>
            <b>
              <u>5.Where is the monetized part?</u>
            </b>
            <br />
            <br />
            <b>Ans:</b> Currently the app is free. As we add more problems, we
            shall have a premium subscription option with a wider range of
            problems and tutorials.
          </Typography>
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen
        onClose={handleClose2}
        aria-labelledby="customized-dialog-title"
        open={open2}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose2}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              About Us
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent dividers>
          <Typography gutterBottom>
            <p>
              Brainlytic offers creative mathematical and logical problems (with
              real-world contexts) to young learners of classes 3 to 10, in both
              English and Bengali. The use of an interactive interface during
              problem solving helps in visualization, and exploration of new
              possibilities. We can also track and display a user’s progress and
              performance. The problems have hints, and come with detailed
              explanations for a beginner-friendly learning experience. Prior
              mistakes are easily accessible for revision and one can share
              interesting problems with peers.
            </p>
            <p>
              For many, the focus of education has shifted to examination and
              memorization, rather than creating love and attraction for
              learning. Many children only practice for the exams and hardly get
              the chance to think outside the box. Again, many enthusiastic
              learners get discouraged seeing the steep learning curve of
              high-standard programs like Math Olympiad. Traditional means of
              teaching are also not favorable for students with attention
              deficit; more so in the online terms due to an increase in
              screen-time.
            </p>
            <p>
              We believe, the solution lies in a platform with a large
              assortment of creative problems of varied difficulty levels. An AI
              guide to monitor the student’s progress will inspire learners of
              all skill levels to participate. With an interactive approach, the
              learners with attention deficit will feel as if they are “Playing
              games”, while actually learning new mathematical concepts and
              practicing logical thinking (Reference). Brainlytic incorporates
              all of these features, bringing you a platform that respects every
              individual’s growth speed, encourages out-ofthe-box thinking, and
              aims to nurture logical thinking and mathematical reasoning.
            </p>
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Footer;
