import React, { useState } from "react";
import { base_url } from "../..";
import "../../css/right-pan.scss";
import achievement_icon from "../../assets/achievement.png";
import badge_icon from "../../assets/badge.png";
import LeftPan from "./LeftPan";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { useEffect } from "react";
const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  gridCentering: {
    [theme.breakpoints.down("1280")]: {
      justifyContent: "center",
    },
  },
}));

const Stats = (props) => {
  const stat = useSelector((state) => state.stat);
  const history = useHistory();
  const [scoreData, setScoreData] = useState([]);
  const [activeLevel, setActiveLevel] = useState(0);
  const fetchScoreData = () => {
    axios
      .get(base_url + "api/score/score-summary/", {
        headers: { authorization: cookies.get("token") },
      })
      .then((res) => {
        setScoreData(res.data);
      });
  };

  useEffect(() => {
    fetchScoreData();
  }, []);

  const classes = useStyles();
  return (
    <div style={{ marginTop: "40px" }}>
      <div className={"achievement-header"}>
        {/* <div className={"achievement-title"}>Progress Summary</div> */}
      </div>
      <div className="achievement-score">
        <h2 className="achievement-score-heading">Your Score</h2>
        <div className="achievement-statistics-levelbar">
          {scoreData.map((data, index) => {
            return (
              <p
                key={data.level}
                onClick={() => {
                  setActiveLevel(index);
                }}
                className={index === activeLevel ? "activelevel" : ""}
              >
                {data.level}
              </p>
            );
          })}
        </div>
        <div className="achievement-statistics-cards">
          {scoreData[activeLevel] &&
            scoreData[activeLevel].topics.map((data) => (
              <ScoreBar
                score={data.score}
                topic={data.topic}
                key={data.topic}
              />
            ))}
        </div>

        <button
          className="achievement-button"
          onClick={() => {
            history.push("/lang/en/progress-tracking");
          }}
        >
          Track Progress
        </button>
        <button
          className="achievement-button"
          onClick={() => {
            history.push("/lang/en/problem/solved");
          }}
        >
          Go to Details{" "}
        </button>
      </div>
    </div>
  );
};

const ScoreBar = ({ score, topic }) => {
  return (
    <div className="scorebar">
      <p>{topic}</p>
   
      <h3>{score}%</h3>
    </div>
  );
};

const RightPanUpdated = (props) => {
  return (
    <div className={"right-main-container"}>
      <Stats />
      {/* <div className="left-pan-right">
          <LeftPan />
        </div> */}
    </div>
  );
};

export default RightPanUpdated;
