import React from "react";

import "../../css/pricing.css";
import data from "../../data.json";
function Pricing() {
  const handleChange = (i) => {
    Array.from(document.getElementsByClassName("tick" + i)).forEach((e) => {
      e.src =
        "https://buet-edu-1.s3.amazonaws.com/auto_upload/I97my9P8SJhfh4WrtV4CTc8xNo92/1626416834223.PNG";
    });
  };
  const leave = (i) => {
    Array.from(document.getElementsByClassName("tick" + i)).forEach((e) => {
      e.src =
        "https://buet-edu-1.s3.amazonaws.com/auto_upload/I97my9P8SJhfh4WrtV4CTc8xNo92/1626410963467.PNG";
    });
  };
  return (
    <>
      <div className="cards">
        <h2
          className="cards-plan"
        >
          Choose your Plan
        </h2>
        <p
          className="cards-best"
        >
          Make the best use of your
          <br />
          young champ's brain and time
        </p>

        <div class="card-pricing">
          {data.map((datum, i) => {
            return (
              <div
                class="card-body-pricing"
                onMouseOver={() => handleChange(i)}
                onMouseLeave={() => leave(i)}
              >
                {datum.popular ? (
                  <div className="popular">
                    <h6 style={{ paddingTop: "5px" }}>Most Popular</h6>
                  </div>
                ) : null}

                {datum.price ? (
                  <div style={{ display: "flex" }}>
                    {" "}
                    <p>BDT {datum.price}</p>
                    {datum.popular ? (
                      <span>/ year</span>
                    ) : datum.month > "1" ? (
                      <span>/ {datum.month} months</span>
                    ) : (
                      <span>/ month</span>
                    )}
                  </div>
                ) : null}
                {datum.category === "Beginner" ? (
                  <p style={{ paddingTop: "100px" }}>{datum.category}</p>
                ) : (
                  <p style={{ paddingTop: "0px" }}>{datum.category}</p>
                )}

                {datum.points.map((point) => {
                  return (
                    <div style={{ display: "flex", marginLeft: "35px" }}>
                      <img
                        className={"tick" + i}
                        style={{ width: "40px", height: "40px" }}
                        src="https://buet-edu-1.s3.amazonaws.com/auto_upload/I97my9P8SJhfh4WrtV4CTc8xNo92/1626410963467.PNG"
                      />
                      <h5>{point.text}</h5>
                    </div>
                  );
                })}

                <button className="pricing-btn">{datum.btn}</button>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Pricing;
