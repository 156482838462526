import React, {useEffect} from 'react'
import {useDispatch} from "react-redux";
import {setBnLang} from "../../action/lang";

const Bn=props=>{

    const dispatch = useDispatch();

    useEffect(()=>{
        setBnLang(dispatch)
    })

    return(
        <div/>
    )
}

export default Bn