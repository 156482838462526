import React, { useEffect, useState } from "react";
import { fetchAttemptedTests, fetchTests } from "../../../action/content";
import "../../../css/topics.css";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  gridCentering: {
    [theme.breakpoints.down("1280")]: {
      justifyContent: "center",
    },
  },
}));

const TestListContainer = (props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container spacing={2} className={classes.gridCentering}>
      {props.tests.map((test, i) => {
        return (
          <Grid item xs={10} lg={6}>
            <div
              className={"topic-container"}
              onClick={() => {
                history.push(
                  `/lang/${props.lang}/level/${props.level}/test/${test.test_id}`
                );
              }}
            >
              <div className={"topic-top-container"}>
                <div className={"topic-top-left"}>
                  <div className={"topic-serial"}>{"0" + (i + 1)}</div>
                  <div className={"topic-title"}>{test.name}</div>
                </div>
                {/* <div className={"topic-top-right"}>
                    <img src={topic.logo} />
                  </div> */}
              </div>
              <div className={"topic-divider"} />
              <div className={"topic-bottom-container"}>
                <div className={"topic-stats"}>
                  {test.total_marks} marks
                  <br />
                  {test.duration} hours
                </div>
                <div className={"topic-personal-stats"}>
                  {/* {topic.problem_solved}/{topic.nproblem} Problems
                    <br />
                    Solved */}
                  {format(
                    new Date(parseInt(test.test_date_time, 10)).getTime(),
                    "do MMMM, yyyy"
                  )}
                  <br />
                  {format(
                    new Date(parseInt(test.test_date_time, 10)).getTime(),
                    "h:mm a"
                  )}
                </div>
              </div>
              <div
                // style={{
                //   width: `${
                //     (parseInt(topic.problem_solved) * 100) / topic.nproblem
                //   }%`,
                //   backgroundColor: topic.color,
                // }}
                style={{
                  width: `0%`,
                  // backgroundColor: test.color,
                }}
                className={"topic-progress-bar"}
              />
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
};
const TestList = (props) => {
  var lang = props.match.params.lang;
  var level = props.match.params.level;

  if (lang === undefined) lang = "en";
  if (level === undefined) level = 1;

  const [tests, setTests] = useState([]);
  const [attemptedTests, setAttemptedTests] = useState([]);

  useEffect(() => {
    fetchTests(lang, level).then((res) => {
      if (res.success) {
        setTests(res.data.test_list);
      }
    });
    fetchAttemptedTests(lang, level).then((res) => {
      if (res.success) {
        setAttemptedTests(res.data.test_list);
      }
    });
  }, [lang, level]);

  return (
    <>
      <div className={"topics-container"}>
        {tests.length > 0 ? (
          <>
            {" "}
            <div className={"topics-title"}>Live Tests</div>
            <TestListContainer tests={tests} lang={lang} level={level} />
          </>
        ) : (
          <></>
        )}
      </div>
      <div className={"topics-container"}>
        {attemptedTests.length > 0 ? (
          <>
            {" "}
            <div className={"topics-title"}>Completed Tests</div>
            <TestListContainer
              tests={attemptedTests}
              lang={lang}
              level={level}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default TestList;
