import React from "react";
import Nav from "../../components/homepage/Nav";
import firstImage from "../../assets/about us/1st img.svg";
import secondImage from "../../assets/about us/2nd img.svg";
import thirdImage from "../../assets/about us/3rd img.svg";
import "./style.scss";

export default function AboutUs() {
  return (
    <div className="about">
      <Nav />

      <div className="about-wrapper">
        <div className="about-wrapper-section">
          <div className="about-wrapper-section-content">
            <img src={firstImage} alt="" />
            <p>
              Brainlytic offers creative mathematical and logical problems (with
              real-world contexts) to young learners of classes 3 to 10, in both
              English and Bengali. The use of an interactive interface during
              problem solving helps in visualization, and exploration of new
              possibilities. We can also track and display a user's progress and
              performance. The problems have hints, and come with detailed
              explanations for a beginner-friendly learning experience. Prior
              mistakes are easily accessible for revision and one can share
              interesting problems with peers.
            </p>
          </div>
        </div>
        <div className="about-wrapper-section section-right">
          <div className="about-wrapper-section-content content-right">
            <p>
              For many, the focus of education has shifted to examination and
              memorization, rather than creating love and attraction for
              learning. Many children only practice for the exams and hardly get
              the chance to think outside the box. Again, many enthusiastic
              learners get discouraged seeing the steep learning curve of
              high-standard programs like Math Olympiad. Traditional means of
              teaching are also not favorable for students with attention
              deficit; more so in the online terms due to an increase in
              screen-time.
            </p>
            <img src={secondImage} alt="" />
          </div>
        </div>
        <div className="about-wrapper-section">
          <div className="about-wrapper-section-content">
            <img src={thirdImage} alt="" />
            <p>
              We believe, the solution lies in a platform with a large
              assortment of creative problems of varied difficulty levels. An AI
              guide to monitor the student's progress will inspire learners of
              all skill levels to participate. With an interactive approach, the
              learners with attention deficit will feel as if they are “Playing
              games”, while actually learning new mathematical concepts and
              practicing logical thinking (Reference). Brainlytic incorporates
              all of these features, bringing you a platform that respects every
              individual's growth speed, encourages out-of-the-box thinking, and
              aims to nurture logical thinking and mathematical reasoning.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
