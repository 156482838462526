import React, { useEffect, useState } from "react";
import "./style.scss";
import { fetchProfile } from "../../action/profile";
import { useSelector, useDispatch } from "react-redux";

import logo from "../../assets/user-dashboard/logo.svg";
import bell from "../../assets/user-dashboard/bell.svg";
import downArrow from "../../assets/user-dashboard/down-arrow.svg";
import gear from "../../assets/user-dashboard/gear.svg";
import hamburger from "../../assets/user-dashboard/hamburger.svg";
import mathLogo from "../../assets/user-dashboard/math.svg";
import proAd from "../../assets/user-dashboard/pro-ad.svg";
import upArrow from "../../assets/user-dashboard/up-arrow.svg";
import user from "../../assets/user-dashboard/user.png";
import jigsaw from "../../assets/user-dashboard/jigsaw.svg";
import score from "../../assets/user-dashboard/score.svg";
import categories from "../../assets/user-dashboard/categories.svg";
import tutorials from "../../assets/user-dashboard/tutorials.svg";
import axios from "axios";
import { base_url } from "../..";
import Cookies from "universal-cookie";
import { fetchTopics } from "../../action/content";
import { active } from "d3";
const cookies = new Cookies();

export default function UserDashboard() {
  const profile = useSelector((state) => state.profile);

  const dispatch = useDispatch();
  const [scoreData, setScoreData] = useState([]);
  const [activeLevelData, setActiveLevelData] = useState({});
  const [scoreDetails, setScoreDetails] = useState([]);
  const [activeLevel, setActiveLevel] = useState(0);
  const [amountSolvedData, setAmountSolvedData] = useState({
    GeometryFirstAttempt: 0,
    GeometrySecondAttempt: 0,
    GeometryMoreAttempt: 0,
    MathFirstAttempt: 0,
    MathSecondAttempt: 0,
    MathMoreAttempt: 0,
    LogicalReasoningFirstAttempt: 0,
    LogicalReasoningSecondAttempt: 0,
    LogicalReasoningMoreAttempt: 0,
  });

  const fetchScoreData = () => {
    axios
      .get(base_url + "api/score/score-summary/", {
        headers: { authorization: cookies.get("token") },
      })
      .then((res) => {
        setScoreData(res.data);
      });
  };

  const fetchScoreDetails = () => {
    axios
      .get(base_url + "api/score/score-details/", {
        headers: { authorization: cookies.get("token") },
      })
      .then((res) => {
        setScoreDetails(res.data);
      });
  };

  const settingSolvedData = () => {
    const LevelData = scoreDetails.filter(
      (data) => data.level === `Level-${activeLevel + 1}`
    );

    //geometry
    const levelGeometryFirstAttempt = LevelData.filter(
      (data) => data.topic === "Geometry" && data.attempts === 1
    ).length;
    const levelGeometrySecondAttempt = LevelData.filter(
      (data) => data.topic === "Geometry" && data.attempts === 2
    ).length;
    const levelGeometryMoreAttempt = LevelData.filter(
      (data) => data.topic === "Geometry" && data.attempts > 2
    ).length;

    //math
    const levelMathFirstAttempt = LevelData.filter(
      (data) => data.topic === "Math" && data.attempts === 1
    ).length;
    const levelMathSecondAttempt = LevelData.filter(
      (data) => data.topic === "Math" && data.attempts === 2
    ).length;
    const levelMathMoreAttempt = LevelData.filter(
      (data) => data.topic === "Math" && data.attempts > 2
    ).length;

    //logical Reasoning
    const levelLogicalReasoningFirstAttempt = LevelData.filter(
      (data) => data.topic === "Logical Reasoning" && data.attempts === 1
    ).length;
    const levelLogicalReasoningSecondAttempt = LevelData.filter(
      (data) => data.topic === "Logical Reasoning" && data.attempts === 2
    ).length;
    const levelLogicalReasoningMoreAttempt = LevelData.filter(
      (data) => data.topic === "Logical Reasoning" && data.attempts > 2
    ).length;

    const tempSolvedData = {
      GeometryFirstAttempt: levelGeometryFirstAttempt,
      GeometrySecondAttempt: levelGeometrySecondAttempt,
      GeometryMoreAttempt: levelGeometryMoreAttempt,
      MathFirstAttempt: levelMathFirstAttempt,
      MathSecondAttempt: levelMathSecondAttempt,
      MathMoreAttempt: levelMathMoreAttempt,
      LogicalReasoningFirstAttempt: levelLogicalReasoningFirstAttempt,
      LogicalReasoningSecondAttempt: levelLogicalReasoningSecondAttempt,
      LogicalReasoningMoreAttempt: levelLogicalReasoningMoreAttempt,
    };
    setAmountSolvedData(tempSolvedData);
  };

  useEffect(() => {
    settingSolvedData();
  }, [activeLevel, scoreDetails]);

  useEffect(() => {
    fetchProfile(dispatch);
    fetchScoreData();
    fetchScoreDetails();
  }, []);

  useEffect(() => {
    // fetchTopics("en", activeLevel + 1).then((res) => {
    //   let temp = {};
    //   // res.topicList.forEach((elem) => {
    //   //   // if (elem.subject.toLowerCase() === "logical thinking") {
    //   //   //   temp["logical reasoning"] = elem["nproblem"];
    //   //   // } else {

    //   //   temp[elem["name"].toLowerCase()] = elem["nproblem"];
    //   //   // }
    //   // });
    //   res.topicList.forEach((elem) => {
    //     // if (elem.subject.toLowerCase() === "logical thinking") {
    //     //   temp["logical reasoning"] = elem["nproblem"];
    //     // } else {

    //     temp[elem["name"].toLowerCase()] = elem["nproblem"];
    //     // }
    //   });
    let temp = {};
    console.log("this is from user-dashboard");
    console.log(scoreData[activeLevel]?.topics);
    scoreData[activeLevel]?.topics.forEach((elem) => {
      // if (elem.subject.toLowerCase() === "logical thinking") {
      //   temp["logical reasoning"] = elem["nproblem"];
      // } else {

      temp[elem["topic"].toLowerCase()] = elem["nproblem"];
      // }
      console.log(temp);

      setActiveLevelData(temp);
    });
  }, [activeLevel,scoreData]);
  
  return (
    <div className="userDashboard">
      {/* <div className="userDashboard-sidemenu">
        <div className="userDashboard-sidemenu-logo">
          <img src={logo} alt="" />
          <img src={hamburger} alt="" />
        </div>
        <div className="userDashboard-sidemenu-menu">
          <div className="userDashboard-sidemenu-menu-option">
            <img src={jigsaw} alt="" />
            <h3>Unsolved Problems</h3>
          </div>
          <div className="userDashboard-sidemenu-menu-option navactive">
            <img src={score} alt="" />
            <h3>Score</h3>
          </div>
          <div className="userDashboard-sidemenu-menu-option">
            <img src={categories} alt="" />
            <h3>Categories</h3>
          </div>
          <div className="userDashboard-sidemenu-menu-option">
            <img src={tutorials} alt="" />
            <h3>Tutorials</h3>
          </div>
        </div>
        <div className="userDashboard-sidemenu-promo">
          <div className="userDashboard-sidemenu-promo-background"></div>
          <img src={proAd} alt="" />
          <p>
            Upgrade to <span>PRO</span> for more features.
          </p>
          <button>Upgrade</button>
        </div>
      </div> */}
      <div className="userDashboard-gap"></div>
      <div className="userDashboard-main">
        {/* <div className="userDashboard-main-nav">
          <div className="userDashboard-main-nav-wrapper">
            <img src={gear} alt="" />
            <img src={bell} alt="" />
            <img
              src={profile && profile.image}
              alt=""
              className="userDashboard-main-nav-wrapper-userpic"
            />
            <p>{profile && profile.name}</p>
          </div>
        </div> */}
        {/* <div className="userDashboard-main-userinfo">
          <div className="userDashboard-main-userinfo-data">
            <p className="pheading">{profile && profile.name}</p>
          </div>
          <div className="userDashboard-main-userinfo-pic">
            <img src={profile && profile.image} alt="" />
          </div>
        </div> */}
        <div className="userDashboard-main-statistics">
          <p className="pheading">Score Summary</p>
          <div className="userDashboard-main-statistics-levelbar">
            {scoreData.map((data, index) => {
              return (
                <p
                  key={data.level}
                  onClick={() => {
                    setActiveLevel(index);
                  }}
                  className={index === activeLevel ? "activelevel" : ""}
                >
                  {data.level}
                </p>
              );
            })}
          </div>
          <div className="userDashboard-main-statistics-cards">
            {scoreData[activeLevel] &&
              scoreData[activeLevel].topics.map((data) => (
                <SectionCards
                  activeLevelData={activeLevelData[data.topic.toLowerCase()]}
                  score={data.score}
                  topic={data.topic}
                  activeLevel={activeLevel + 1}
                  firstAttempt={
                    data.topic === "Math"
                      ? amountSolvedData.MathFirstAttempt
                      : data.topic === "Geometry"
                      ? amountSolvedData.GeometryFirstAttempt
                      : data.topic === "Logical Reasoning"
                      ? amountSolvedData.LogicalReasoningFirstAttempt
                      : 0
                  }
                  secondAttempt={
                    data.topic === "Math"
                      ? amountSolvedData.MathSecondAttempt
                      : data.topic === "Geometry"
                      ? amountSolvedData.GeometrySecondAttempt
                      : data.topic === "Logical Reasoning"
                      ? amountSolvedData.LogicalReasoningSecondAttempt
                      : 0
                  }
                  moreAttempt={
                    data.topic === "Math"
                      ? amountSolvedData.MathMoreAttempt
                      : data.topic === "Geometry"
                      ? amountSolvedData.GeometryMoreAttempt
                      : data.topic === "Logical Reasoning"
                      ? amountSolvedData.LogicalReasoningMoreAttempt
                      : 0
                  }
                />
              ))}
          </div>
        </div>
        {/* <div className="userDashboard-main-rankings">
          <p className="pheading">Rankings</p>
          <div className="userDashboard-main-rankings-cards">
            <div className="userDashboard-main-rankings-cards-card">
              <div className="userDashboard-main-rankings-cards-card-left">
                <p>Level 1</p>
                <h4>18</h4>
              </div>
              <div className="userDashboard-main-rankings-cards-card-right">
                <img src={upArrow} alt="" />
              </div>
            </div>
            <div className="userDashboard-main-rankings-cards-card">
              <div className="userDashboard-main-rankings-cards-card-left">
                <p>Level 1</p>
                <h4>18</h4>
              </div>
              <div className="userDashboard-main-rankings-cards-card-right">
                <img src={upArrow} alt="" />
              </div>
            </div>
            <div className="userDashboard-main-rankings-cards-card">
              <div className="userDashboard-main-rankings-cards-card-left">
                <p>Level 1</p>
                <h4>18</h4>
              </div>
              <div className="userDashboard-main-rankings-cards-card-right">
                <img src={upArrow} alt="" />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

const SectionCards = ({
  score,
  topic,
  activeLevel,
  firstAttempt,
  secondAttempt,
  moreAttempt,
  activeLevelData,
}) => {
  return (
    <div className="userDashboard-main-statistics-cards-card">
      <div className="userDashboard-main-statistics-cards-card-top">
        <div className="userDashboard-main-statistics-cards-card-top-title">
          <p>{topic}</p>
          <div className="userDashboard-main-statistics-cards-card-top-title-bottom">
            <h4>{score}%</h4>
            <div className="userDashboard-main-statistics-cards-card-top-title-bottom-chip">
              <p>Current Score</p>
            </div>
          </div>
        </div>
        <div className="userDashboard-main-statistics-cards-card-top-img">
          <img src={mathLogo} alt="" />
        </div>
      </div>
      <div className="userDashboard-main-statistics-cards-card-bottom">
        <div className="userDashboard-main-statistics-cards-card-bottom-data">
          <p>Solved in First attempt</p>
          <p>
            {firstAttempt} x 1 = {firstAttempt * 1}
          </p>
          {/* {activeLevel === 1 ? (
            <p>
              {firstAttempt}x10 = {firstAttempt * 10}
            </p>
          ) : activeLevel === 2 ? (
            <p>
              {" "}
              {firstAttempt}x20 = {firstAttempt * 20}
            </p>
          ) : activeLevel === 3 ? (
            <p>
              {" "}
              {firstAttempt}x30 = {firstAttempt * 30}
            </p>
          ) : (
            ""
          )} */}
        </div>
        <div className="userDashboard-main-statistics-cards-card-bottom-data">
          <p>Solved in Second attempt</p>
          <p>
            {secondAttempt} x .5 = {secondAttempt * 0.5}
          </p>
          {/* {activeLevel === 1 ? (
            <p>
              {secondAttempt}x5 = {secondAttempt * 5}
            </p>
          ) : activeLevel === 2 ? (
            <p>
              {" "}
              {secondAttempt}x10 = {secondAttempt * 10}
            </p>
          ) : activeLevel === 3 ? (
            <p>
              {" "}
              {secondAttempt}x15 = {secondAttempt * 15}
            </p>
          ) : (
            ""
          )} */}
        </div>
        <div className="userDashboard-main-statistics-cards-card-bottom-data">
          <p>Solved in more than two attempts</p>
          <p>
            {moreAttempt} x .25 = {moreAttempt * 0.25}
          </p>

          {/* {activeLevel === 1 ? (
            <p>
              {moreAttempt}x2.5 = {moreAttempt * 2.5}
            </p>
          ) : activeLevel === 2 ? (
            <p>
              {" "}
              {moreAttempt}x5 = {moreAttempt * 5}
            </p>
          ) : activeLevel === 3 ? (
            <p>
              {" "}
              {moreAttempt}x7.5 = {moreAttempt * 7.5}
            </p>
          ) : (
            ""
          )} */}
          {/* {2 + 0.5 / activeLevelData[topic.lower()]} */}
        </div>
        <div className="userDashboard-main-statistics-cards-card-bottom-data">
          <p>
            Total Score: ( {firstAttempt} x 1 + {secondAttempt} x 0.5 +{" "}
            {moreAttempt} x 0.25 ) ÷ {activeLevelData} = {score}
          </p>
        </div>
      </div>
    </div>
  );
};
