import React from "react";
import TopBody from "./TopBody";

const Homepage = (props) => {
  return (
    <div className="homepage-main">
      <TopBody history={props.history} />
    </div>
  );
};

export default Homepage;
