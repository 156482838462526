import React, { useEffect, useState } from "react";
import "../../css/main.css";
import axios from "axios";
import Cookies from "universal-cookie";

import { base_url } from "../..";
const cookies = new Cookies();

const Levels = (props) => {
  const [levels, setLevels] = useState([]);
  var l = props.history.location.pathname.split("/")[4];
  if (l === undefined) l = 1;

  const [level, setLevel] = useState(l);

  useEffect(() => {
    setLevel(l);
  }, [l]);

  const shiftLevel = (l) => {
    setLevel(l);
    props.history.push(`/lang/en/level/${l}`);
  };

  useEffect(() => {
    console.log(props.history.location.pathname.split("/"));
    if (props.history.location.pathname.split("/").length <= 5) shiftLevel(1);
  }, []);

  return (
    <div className={"level-container-root"}>
      <div className={"level-container"}>
        {Array.from(Array(4), (e, i) => {
          return (
            <div
              onClick={() => {
                shiftLevel(i + 1);
              }}
              className={i === level - 1 ? "level-active" : "level-inactive"}
            >
              Level {i + 1}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Levels;
