import React from 'react'
import objective_card from './img/objective card.svg'

const TopCarouselCard2 = () =>{
    return(
        <div className="homepage-topbody-hero-text-topCarouselCard2">
            <img src={objective_card} alt="" />
        </div>
    )

}


export default TopCarouselCard2;
