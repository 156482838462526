import React from 'react'
import level_system_card from './img/level system top page.svg'

const TopCarouselCard1 = () =>{
    return(
        <div className="homepage-topbody-hero-text-topCarouselCard1">
            <img src={level_system_card} alt="" />
        </div>
    )


}


export default TopCarouselCard1;