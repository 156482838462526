import React from "react";
import "../../css/left-pan.css";
import logo from "../../assets/icons/logo.svg";
import sort from "../../assets/sort.png";
import problem_logo from "../../assets/problem-logo-dummy.png";
import fire_icon from "../../assets/fire-icon.png";
import search_icon from "../../assets/search-icon.png";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const LeftPan = (props) => {
  return (
    <div className={"left-main-container"}>
      <div className={"left-top"}>
        <div
          className={"left-top-icon"}
          onClick={() => {
            props.history.push("/lang/en/level/1");
          }}
        >
          <img src={logo} />
        </div>
        <div className={"left-top-sort-icon"}>
          <img src={sort} />
        </div>
      </div>
      <div className={"left-search-container"}>
        <input type="text" placeholder="Search.." />
        <div className={"left-search-image"}>
          <img src={search_icon} />
        </div>
      </div>
      <ErrorList history={props.history} />
    </div>
  );
};

const ErrorList = (props) => {
  const erroredProblems = useSelector((state) => state.erroredProblems);
  const history = useHistory();
  return (
    <div
      className={"left-list-container vbox"}
      style={{ height: "87%", justifyContent: "space-between" }}
    >
      {erroredProblems !== null && erroredProblems.length > 0 ? (
        <div>
          <div className={"left-list-container-header-title"}>
            Problems you got stuck
          </div>
          {erroredProblems.map((problem, idx) => {
            return idx > 2 ? (
              <div />
            ) : (
              <div className={"left-list-container-list"}>
                <ProblemCard history={props.history} problem={problem} />
              </div>
            );
          })}
        </div>
      ) : (
        <div />
      )}
      {erroredProblems !== null && erroredProblems.length > 3 ? (
        <button
          className="achievement-button"
          onClick={() => {
            history.push("/lang/en/problem/unsolved");
          }}
          // style={{ marginBottom: 0 }}
        >
          Show all
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

const RecomList = (props) => {
  return (
    <div className={"left-list-container"}>
      <div className={"left-list-container-header"}>
        <div className={"left-list-container-header-title"}>
          Recommended by friends
        </div>
        <div className={"left-list-container-header-show-all"}>Show all</div>
      </div>
      <div className={"left-list-container-list"}>
        <ProblemCard />
      </div>
    </div>
  );
};

const ProblemCard = (props) => {
  //console.log(props.problem)

  return (
    <div
      onClick={() => {
        props.history.push(
          `/lang/en/level/${props.problem.grade}/series/${props.problem.series_id}/problem/${props.problem.serial}`
        );
      }}
      className={"left-problem-card-container"}
    >
      <div className={"left-problem-card-logo"}>
        <img src={props.problem.logo} />
      </div>
      <div className={"left-problem-card-divider"} />
      <div className={"left-problem-card-bottom-container"}>
        <div className={"left-problem-card-title"}>
          {props.problem.problem_title}
        </div>
        <div className={"left-problem-card-description"}>
          {props.problem.topic_name} {">"} {props.problem.series_name}
        </div>
        <div className={"left-problem-card-bottom"}>
          <div className={"left-problem-card-bottom-icon"}>
            <img src={fire_icon} />
          </div>
          <div className={"left-problem-card-bottom-level"}>
            Level {props.problem.grade}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftPan;
