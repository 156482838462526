import React, { useEffect, useState } from "react";
import {
  fetchTestById,
  getLeaderBoard,
  getResultOfTest,
} from "../../../action/content";
import "../../../css/problem-container.css";
import "../../../css/test.css";
import "../../../css/timer.css";
import "../../../css/scoreboard.css";
import ScoreBoard from "./ScoreBoard";

const TestScore = ({ score, test, answers }) => {
  return (
    <>
      <div style={{ width: "90%", margin: "auto" }}>
        <div className="hbox">
          <div
            className="topic-container"
            style={{
              padding: "0.5rem",
              marginBottom: "0.5rem",
              backgroundColor: "#3D7EFF",
              width: "40vw",
            }}
          >
            Problem Title
          </div>
          <div
            className="topic-container"
            style={{
              padding: "0.5rem",
              marginBottom: "0.5rem",
              backgroundColor: "#3D7EFF",
              width: "17vw",
            }}
          >
            Marks
          </div>
        </div>
        {answers.map((answer, index) => {
          return (
            <div className="hbox">
              <div
                className="topic-container hbox"
                style={{ padding: 0, width: "40vw", marginBottom: "0.5rem" }}
              >
                <div
                  style={{
                    backgroundColor: "#3D7EFF",
                    padding: "0.5rem",
                    width: "10%",
                  }}
                >
                  {index + 1}
                </div>
                <div
                  style={{
                    padding: "0.5rem",
                    width: "90%",
                  }}
                >
                  {answer.title}
                </div>
              </div>
              <div
                className="topic-container"
                style={{
                  padding: "0.5rem",
                  marginBottom: "0.5rem",
                  backgroundColor: answer.is_correct ? "#45C881" : "#E84374",
                  width: "17vw",
                }}
              >
                {answer.is_correct ? answer.marks : 0}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const LeaderList = ({ leaders }) => {
  return leaders.map((leader, index) => {
    return (
      <div className="hbox">
        <div
          className="topic-container hbox"
          style={{ padding: 0, width: "40vw", marginBottom: "0.5rem" }}
        >
          <div
            style={{
              backgroundColor: "#3D7EFF",
              padding: "0.5rem",
              width: "10%",
            }}
          >
            {index + 1}
          </div>
          <div
            style={{
              padding: "0.5rem",
              width: "90%",
            }}
          >
            {leader.name}
          </div>
        </div>
        <div
          className="topic-container"
          style={{
            padding: "0.5rem",
            marginBottom: "0.5rem",
            // backgroundColor: "#45C881",
            width: "17vw",
          }}
        >
          {leader.obtained_marks}
        </div>
      </div>
    );
  });
};
const LeaderBoard = ({ leaders }) => {
  console.log(leaders);
  return (
    <>
      <div style={{ width: "90%", margin: "auto" }}>
        <div className="hbox">
          <div
            className="topic-container"
            style={{
              padding: "0.5rem",
              marginBottom: "0.5rem",
              backgroundColor: "#3D7EFF",
              width: "40vw",
            }}
          >
            User name
          </div>
          <div
            className="topic-container"
            style={{
              padding: "0.5rem",
              marginBottom: "0.5rem",
              backgroundColor: "#3D7EFF",
              width: "17vw",
            }}
          >
            Marks
          </div>
        </div>
        {leaders.length > 0 ? (
          <LeaderList leaders={leaders} />
        ) : (
          <div
            className="topic-container"
            style={{
              height: "35vh",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              padding: "0",
              fontSize: "2rem",
              fontWeight: "bold",
              color: "#D3D3D3",
            }}
          >
            Leaderboard not published yet
          </div>
        )}
      </div>
    </>
  );
};
const Toggler = ({ showScore, setShowScore }) => {
  const shiftLevel = (l) => {
    if (l == 0) setShowScore(true);
    else setShowScore(false);
  };
  return (
    <div
      className={"level-container-root"}
      style={{ width: "90%", margin: "auto", marginBottom: "1rem" }}
    >
      <div className={"level-container"}>
        <div
          onClick={() => {
            shiftLevel(0);
          }}
          className={showScore ? "level-active" : "level-inactive"}
          style={{ width: "50%" }}
        >
          Score Board
        </div>
        <div
          onClick={() => {
            shiftLevel(1);
          }}
          className={!showScore ? "level-active" : "level-inactive"}
          style={{ width: "50%" }}
        >
          Leader Board
        </div>
      </div>
    </div>
  );
};
const TestResult = ({ testId }) => {
  const [score, setScore] = useState(undefined);
  const [test, setTest] = useState(undefined);
  const [answers, setAnswers] = useState(undefined);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showScore, setShowScore] = useState(true);
  const [leaders, setLeaders] = useState(undefined);
  const fetchResult = () => {
    getResultOfTest(testId).then((res2) => {
      if (res2.data.success) {
        setScore(res2.data.result.score);
        setAnswers(res2.data.result.answers);
      }
    });
  };

  const fetchLeaderBoard = () => {
    getLeaderBoard(testId).then((res2) => {
      if (res2.data.success) {
        setLeaders(res2.data.data);
      }
    });
  };

  useEffect(() => {
    if (testId !== undefined) {
      fetchTestById(testId).then((res) => {
        console.log(res);
        if (res.success) {
          setTest(res.data.test_details.test);
          console.log(res.data.test_details.test);
          setIsSubmitted(res.data.is_submitted);
        }
      });
    }
  }, [testId]);

  useEffect(() => {
    if (answers === undefined && testId !== undefined) {
      fetchResult();
      fetchLeaderBoard();
    }
  }, [isSubmitted]);

  return (
    <div>
      {score !== undefined &&
      test !== undefined &&
      answers !== undefined &&
      leaders !== undefined ? (
        <div
          style={{ textAlign: "center", padding: "3rem" }}
          // className={"topic-container"}
        >
          <ScoreBoard obtained_score={score} total_score={test.total_marks} />
          <Toggler showScore={showScore} setShowScore={setShowScore} />
          {showScore ? (
            <TestScore score={score} answers={answers} test={test} />
          ) : (
            <LeaderBoard leaders={leaders} />
          )}
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default TestResult;
