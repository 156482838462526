import React, { useEffect, useState } from "react";
import { fetchTopics } from "../../action/content";
import "../../css/topics.css";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import TestList from "./test/TestList";

const useStyles = makeStyles((theme) => ({
  gridCentering: {
    [theme.breakpoints.down("1280")]: {
      justifyContent: "center",
    },
  },
}));

const TopicList = (props) => {
  const classes = useStyles();

  var lang = props.match.params.lang;
  var level = props.match.params.level;

  if (lang === undefined) lang = "en";
  if (level === undefined) level = 1;
  if (lang === undefined) lang = "en";
  if (level === undefined) level = 1;

  const [topics, setTopics] = useState([]);

  useEffect(() => {
    fetchTopics(lang, level).then((res) => {
      console.log(res);
      setTopics(res.topicList);
    });
  }, [lang, level]);

  return (
    <>
      <div className={"topics-container"}>
        <div className={"topics-title"}>Topics</div>
        <Grid container spacing={2} className={classes.gridCentering}>
          {topics.map((topic, i) => {
            return (
              <Grid item xs={10} lg={6}>
                <div
                  className={"topic-container"}
                  onClick={() => {
                    props.history.push(
                      `/lang/${lang}/level/${level}/topic/${topic.topic_id}`
                    );
                  }}
                >
                  <div className={"topic-top-container"}>
                    <div className={"topic-top-left"}>
                      <div className={"topic-serial"}>{"0" + (i + 1)}</div>
                      <div className={"topic-title"}>{topic.name}</div>
                    </div>
                    <div className={"topic-top-right"}>
                      <img src={topic.logo} />
                    </div>
                  </div>
                  <div className={"topic-divider"} />
                  <div className={"topic-bottom-container"}>
                    <div className={"topic-stats"}>
                      {topic.nseries} Series
                      <br />
                      {topic.nproblem} Problems
                    </div>
                    <div className={"topic-personal-stats"}>
                      {topic.problem_solved}/{topic.nproblem} Problems
                      <br />
                      Solved
                    </div>
                  </div>
                  <div
                    style={{
                      width: `${
                        (parseInt(topic.problem_solved) * 100) / topic.nproblem
                      }%`,
                      backgroundColor: topic.color,
                    }}
                    className={"topic-progress-bar"}
                  />
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
      <TestList match={props.match} />
    </>
  );
};

export default TopicList;
