import React from "react";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./style.scss";
import { FormControl, InputLabel, OutlinedInput } from "@material-ui/core";
import ProgressController from "../../controller/progressController";
import { getTime, getTimeStamp } from "../../service/DateUtils";
import { format } from "date-fns";

import { styled } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell, { tableCellClasses } from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import FireIcon from "../../assets/fire-icon-black.svg";
const progressController = new ProgressController();

const Toggler = ({ filter, setFilter }) => {
  const shiftLevel = (l) => {
    if (l == 0) setFilter({ type: "weekly", count: 1 });
    else setFilter({ type: "monthly", count: 1 });
  };
  return (
    <div
      className={"level-container-root"}
      style={{ margin: "0", padding: "0", width: "100%" }}
    >
      <div className={"level-container"} style={{ margin: "0", padding: "0" }}>
        <div
          onClick={() => {
            shiftLevel(0);
          }}
          className={
            filter.type === "weekly" ? "level-active" : "level-inactive"
          }
          style={{ width: "50%" }}
        >
          Weekly
        </div>
        <div
          onClick={() => {
            shiftLevel(1);
          }}
          className={
            filter.type === "monthly" ? "level-active" : "level-inactive"
          }
          style={{ width: "50%" }}
        >
          Monthly
        </div>
      </div>
    </div>
  );
};
const NumberField = (props) => {
  return (
    <FormControl
      fullWidth
      className="input-field"
      variant="outlined"
      size="small"
    >
      <InputLabel htmlFor="outlined-adornment" className="input-label">
        {/* {props.label} */}
      </InputLabel>
      <OutlinedInput
        required
        placeholder="1"
        inputProps={{
          step: props.step,
          min: props.min,
          // max: props.max,
        }}
        id="outlined-adornment"
        className="outlined-input"
        type="number"
        value={props.value.toString()}
        onChange={props.onChange(props.id)}
        label={props.label}
        endAdornment={props.endAdornment}
      />
    </FormControl>
  );
};

const BarChart = (props) => {
  // console.log(props.attempted);
  const [chart, setChart] = useState(undefined);

  useEffect(() => {
    // console.log("Triggered");
    setChart({
      series: [
        {
          name: "Attempted",
          data: props.attempted,
        },
        {
          name: "Solved",
          data: props.solved,
        },
        // {
        //   name: "Unsolved",
        //   data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 50, 45, 48],
        // },
      ],
      options: {
        chart: {
          type: "bar",
          height: 340,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        grid: {
          row: {
            // colors: ["#fff", "#f2f2f2"],
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["transparent"],
        },
        xaxis: {
          categories: props.categories,
          labels: {
            style: {
              colors: [],
              fontSize: "0.8rem",
            },
            rotate: -45,
            rotateAlways: true,
            tickPlacement: "on",
          },
        },
        yaxis: {
          title: {
            text: "Number of problems",
            style: {
              color: undefined,
              fontSize: "0.9rem",
              fontWeight: "1px",
            },
          },
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " problems";
            },
          },
        },
      },
    });
  }, [props]);
  return (
    <>
      {chart !== undefined && props.attempted.length > 0 ? (
        <ReactApexChart
          options={chart.options}
          series={chart.series}
          type="bar"
          height={350}
        />
      ) : (
        <div />
      )}
    </>
  );
};

const ProgressChart = ({ filter }) => {
  const [solveCountArray, setSolveCountArray] = useState([]);
  const [attemptCountArray, setAttemptCountArray] = useState([]);
  const [progressChartXAxis, setProgressChartXAxis] = useState([]);

  const [allSolvedProblems, setAllSolvedProblems] = useState(undefined);
  const [allSubmissions, setAllSubmissions] = useState(undefined);
  const fetchData = async () => {
    const res1 = await progressController.getAllSolvedProblems();
    if (res1.success) {
      setAllSolvedProblems(res1.data);
    }
    const res2 = await progressController.getAllSubmissions();
    if (res2.success) {
      setAllSubmissions(res2.data);
    }
  };

  const setChart = () => {
    // Need to ignore all the attempts after a problem is solved
    // Map of problem_id, solve_time
    // If problem_id exists in map and solve_time < submission_time, ignore that submission
    if (filter.count === 0) return;

    const solveMap = new Map();
    const totalDays = filter.count * (filter.type === "weekly" ? 7 : 30);
    const today = getTime(0);
    const DAY_TO_MILLIS = 24 * 3600 * 1000;
    const startTime = today.getTime() - (totalDays - 1) * DAY_TO_MILLIS;

    const attemptByDay = new Array(totalDays);
    const solveByDay = new Array(totalDays);

    for (let i = 0; i < totalDays; i++) {
      attemptByDay[i] = new Set();
      solveByDay[i] = new Set();
    }

    allSolvedProblems.forEach((problem) => {
      const submissionTime = Number(problem.timestamp);
      solveMap.set(problem.problem_id, problem.timestamp);

      if (startTime <= submissionTime) {
        const day = Math.floor((submissionTime - startTime) / DAY_TO_MILLIS);
        solveByDay[day].add(problem.problem_id);
      }
    });

    allSubmissions.forEach((problem) => {
      if (solveMap.has(problem.problem_id)) {
        if (solveMap.get(problem.problem_id) < problem.timestamp) {
          // console.log(problem.problem_id + " submission ignored");
          return;
        }
      }
      const submissionTime = Number(problem.timestamp);
      // console.log(new Date(startTime), new Date(submissionTime), problem);
      if (startTime <= submissionTime) {
        const day = Math.floor((submissionTime - startTime) / DAY_TO_MILLIS);
        attemptByDay[day].add(problem.problem_id);
      }
    });

    // console.log(attemptByDay);
    const daysPerBar =
      filter.type === "weekly" ? filter.count : filter.count * 5;

    const totalBars = totalDays / daysPerBar;
    const attemptByUnit = new Array(totalBars);
    const solveByUnit = new Array(totalBars);

    for (let i = 0; i < totalBars; i++) {
      attemptByUnit[i] = new Set();
      solveByUnit[i] = new Set();
    }

    // console.log("Total bars: ", totalBars, daysPerBar, attemptByDay);
    for (let i = 0; i < totalDays; i += daysPerBar) {
      for (let j = 0; j < daysPerBar; j++) {
        // console.log(typeof i, ",", j, ",", attemptByDay[i + j]);
        const idx = Math.floor(i / daysPerBar);
        attemptByDay[i + j].forEach(attemptByUnit[idx].add, attemptByUnit[idx]);
        solveByDay[i + j].forEach(solveByUnit[idx].add, solveByUnit[idx]);
        // console.log(i + j, attemptByDay[i + j], attemptByUnit[idx]);
      }
      // console.log(typeof i, typeof daysPerBar);
    }

    const attemptCount = new Array(totalBars);
    const solveCount = new Array(totalBars);
    const dayXAxis = new Array(totalBars);
    for (let i = 0; i < totalBars; i++) {
      attemptCount[i] = attemptByUnit[i].size;
      solveCount[i] = solveByUnit[i].size;
      const s = new Date(startTime + i * daysPerBar * DAY_TO_MILLIS);
      const e = new Date(
        startTime + ((i + 1) * daysPerBar - 1) * DAY_TO_MILLIS
      );

      if (s.getTime() === e.getTime()) {
        dayXAxis[i] = format(s, "dd MMM");
      } else {
        dayXAxis[i] = format(s, "d MMM") + " - " + format(e, "d MMM");
      }
      // console.log(i, dayXAxis[i]);
    }

    console.log(attemptByDay, solveByDay);
    setSolveCountArray(solveCount);
    setAttemptCountArray(attemptCount);
    setProgressChartXAxis(dayXAxis);
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // while (allSolvedProblems !== undefined) {}
    if (allSolvedProblems !== undefined && allSubmissions !== undefined)
      setChart();
  }, [filter, allSolvedProblems, allSubmissions]);
  return (
    <div
      style={{
        padding: "1rem",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        borderRadius: "4px",
      }}
    >
      <BarChart
        attempted={attemptCountArray}
        solved={solveCountArray}
        categories={progressChartXAxis}
      />
    </div>
  );
};

function StickyTable(props) {
  return (
    <Paper
      sx={{
        width: "100%",
        height: props.height,
        marginTop: "1rem",
        overflow: "auto",
      }}
    >
      <TableContainer sx={{ height: "97%", marginTop: "1rem" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props.columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    background: "#f3f6f9",
                    color: "black",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row, idx) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.class}
                  sx={{ cursor: "pointer" }}
                >
                  {props.columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{ background: "#f3f6f9" }}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

const SolvedProbCount = ({ filter, setTotalSolved }) => {
  const [solveTable, setSolveTable] = useState(undefined);
  const [allSolvedProblems, setAllSolvedProblems] = useState(undefined);
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);
  const fetchData = async () => {
    const res1 = await progressController.getAllSolvedProblems();
    if (res1.success) {
      setAllSolvedProblems(res1.data);
    }
  };

  const setData = () => {
    if (filter.count === 0) return;
    const totalDays = filter.count * (filter.type === "weekly" ? 7 : 30);
    const today = getTime(0);
    const DAY_TO_MILLIS = 24 * 3600 * 1000;
    const startTime = today.getTime() - (totalDays - 1) * DAY_TO_MILLIS;

    const table = new Array(3);
    var total = 0;
    for (let i = 0; i < 3; i++) table[i] = new Array(4);
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 4; j++) {
        table[i][j] = 0;
      }
    }

    allSolvedProblems.forEach((problem) => {
      const submissionTime = Number(problem.timestamp);
      if (startTime <= submissionTime) {
        total++;
        // array.push(problem.problem_id);
        let i =
          problem.topic == "Math" ? 0 : problem.topic == "Geometry" ? 2 : 1;
        let j =
          problem.level == "Level-1"
            ? 0
            : problem.level == "Level-2"
            ? 1
            : problem.level == "Level-3"
            ? 2
            : 3;
        table[i][j]++;
      }
    });

    console.log(table);
    setTotalSolved(total);
    setSolveTable(table);
  };

  const createData = (topic, level1, level2, level3, level4) => {
    return { topic, level1, level2, level3, level4 };
  };

  const MathLabel = () => {
    return (
      <div
        className="hbox"
        style={{
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#E84374",
            width: "0.7rem",
            height: "0.7rem",
            borderRadius: "50%",
          }}
        ></div>
        <div>Math</div>
      </div>
    );
  };

  const LogicalLabel = () => {
    return (
      <div
        className="hbox"
        style={{
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#45C881",
            width: "0.7rem",
            height: "0.7rem",
            borderRadius: "50%",
          }}
        ></div>
        <div>Logical Reasoning</div>
      </div>
    );
  };

  const GeoLabel = () => {
    return (
      <div
        className="hbox"
        style={{
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#3D7EFF",
            width: "0.7rem",
            height: "0.7rem",
            borderRadius: "50%",
          }}
        ></div>
        <div>Geometry</div>
      </div>
    );
  };

  const LevelLabel = ({ level }) => {
    return (
      <div
        className="hbox"
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={FireIcon} alt="" />
        <div>Level {level}</div>
      </div>
    );
  };
  const setTable = () => {
    setCols([
      { id: "topic", label: "", align: "left" },
      { id: "level1", label: <LevelLabel level={1} />, align: "center" },
      { id: "level2", label: <LevelLabel level={2} />, align: "center" },
      { id: "level3", label: <LevelLabel level={3} />, align: "center" },
      { id: "level4", label: <LevelLabel level={4} />, align: "center" },
    ]);
    setRows([
      createData(
        <MathLabel />,
        solveTable[0][0],
        solveTable[0][1],
        solveTable[0][2],
        solveTable[0][3]
      ),
      createData(
        <LogicalLabel />,
        solveTable[1][0],
        solveTable[1][1],
        solveTable[1][2],
        solveTable[1][3]
      ),
      createData(
        <GeoLabel />,
        solveTable[2][0],
        solveTable[2][1],
        solveTable[2][2],
        solveTable[2][3]
      ),
    ]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (allSolvedProblems !== undefined) setData();
  }, [filter, allSolvedProblems]);

  useEffect(() => {
    if (solveTable !== undefined) {
      setTable();
    }
  }, [solveTable]);
  return <StickyTable columns={cols} rows={rows} height="86vh" />;
};

const ScoreBar = ({ count }) => {
  return (
    <div
      className="scorebar hbox"
      style={{
        alignItems: "center",
        justifyContent: "space-between",
        padding: ".2rem 1.2rem",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        borderRadius: "4px",
      }}
    >
      <p style={{ fontWeight: "500" }}>Solved Problems</p>
      <h3>{count}</h3>
    </div>
  );
};

const ProgressTracking = () => {
  const [filter, setFilter] = useState({ type: "weekly", count: 1 });
  const [totalSolved, setTotalSolved] = useState(0);
  const handleChange = (prop) => (event) => {
    setFilter({ ...filter, [prop]: Number(event.target.value) });
  };
  return (
    <div className={"topics-container"}>
      <div
        className="hbox"
        style={{ justifyContent: "space-between", marginBottom: "1.5rem" }}
      >
        <div className="vbox">
          <div className={"topics-title"} style={{ marginBottom: "0rem" }}>
            Progress Tracking
          </div>
          <ScoreBar count={totalSolved} />
        </div>

        <div className="vbox" style={{ width: "12rem" }}>
          <div>
            <Toggler filter={filter} setFilter={setFilter} />
          </div>
          <NumberField
            label={filter.type === "weekly" ? "Weeks" : "Months"}
            step={1}
            min={1}
            max={10}
            value={filter.count}
            type="number"
            onChange={handleChange}
            id={"count"}
          />
        </div>
      </div>

      <SolvedProbCount filter={filter} setTotalSolved={setTotalSolved} />
      <div style={{ marginTop: "1.5rem" }}>
        <ProgressChart filter={filter} />
      </div>
    </div>
  );
};

export default ProgressTracking;
